<template>
  <div>
    <validation-observer
      ref="serviceRequestFormSave"
    >
      <b-form @submit.prevent="submitSaveForm">
        <b-row
          class="content-header v-sticky-sidebar-container service_request_task collapse__form-wrapper"
          :class="{ 'white-background': !editFormInOtherStatus, 'green-background': editFormInOtherStatus }"
          style="border-radius: 6px;"
        >
          <!-- Content Left -->
          <b-col
            class="content-header-left my-1 top-info-right"
            cols="12"
            md="3"
          >
            <div
              class="user_block ac_type"
              style="float: none;"
            >
              <div class="user_avatar" />
              <div class="user_info">
                <h6>Status</h6>
                <h5 class="">
                  {{ requestForm.status }}
                </h5>
              </div>
            </div>

            <b-card
              v-if="editFormInOtherStatus"
              class="mt-2 service-forms-card success-card"
            >
              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class=""
                    size="20"
                    style="color: #fff;"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  <span class="step-class">you're now</span>
                  Editing this form
                </h3>
              </template>
              <b-card-text
                class="signing-stage__title"
              >
                <span>After submitting the signed form, the system will generate a signed PDF copy and email to customer.</span>
              </b-card-text>
            </b-card>

            <app-collapse
              v-if="requestForm.status == 'To prepare' || editFormInOtherStatus"
              accordion
              class="mt-2 service-form-collapse"
            >
              <app-collapse-item
                ref="urnDetailsRef"
                title="Urn Repository Details"
                class="custom-collapse-icon"
                :is-visible="urnCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Urn Repository Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Niche No. 福位编号"
                      label-for="nicheNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Niche No."
                        vid="nicheNo"
                        rules=""
                      >
                        <b-form-input
                          id="nicheNo"
                          ref="nicheNo"
                          v-model="nicheNo"
                          readonly
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="File No. 文件号码"
                      label-for="fileNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="File No."
                        vid="fileNo"
                        rules="required"
                      >
                        <b-form-input
                          id="fileNo"
                          ref="fileNo"
                          v-model="fileNo"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Imported Urn Type 骨瓮款式"
                      label-for="urnType"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Imported Urn Type"
                        vid="urnType"
                        rules="required"
                      >
                        <b-form-input
                          id="urnType"
                          ref="urnType"
                          v-model="urnType"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Unit 数量"
                      label-for="unit"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Unit"
                        vid="unit"
                        rules="required"
                      >
                        <b-form-input
                          id="unit"
                          ref="unit"
                          v-model="unit"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="purchaserDetailsRef"
                :is-visible="purchaserDetailsCollapse"
                title="Purchaser Details"
                class="custom-collapse-icon"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Purchaser Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label label-required-star">I as Purchaser/Authorized Representative 我, 购买者/购买者之委托人</span>
                    </div>
                    <b-form-group
                      v-if="currentCustomerNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Representative"
                        rules="required"
                      >
                        <b-form-input
                          id="input-default"
                          ref="customerName"
                          v-model="customerName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': customerNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeCustomerNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="customerNameImage"
                        v-b-modal.customer-name-modal
                        :src="customerNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentCustomerNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeCustomerNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeCustomerNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Address 地址"
                      label-for="customerAddress"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Address"
                        vid="customerAddress"
                        rules="required"
                      >
                        <b-form-textarea
                          id="customerAddress"
                          ref="customerAddress"
                          v-model="customerAddress"
                          rows="3"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="NRIC 身份证号码"
                      label-for="customerNRIC"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="NRIC"
                        vid="customerNRIC"
                        rules="required"
                      >
                        <b-form-input
                          id="customerNRIC"
                          ref="customerNRIC"
                          v-model="customerNRIC"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Contact No. 联络号码"
                      label-for="customerContact"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="customerContact"
                        vid="customerContact"
                        rules=""
                      >
                        <b-form-input
                          id="customerContact"
                          ref="customerContact"
                          v-model="customerContact"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="kinDetailsRef"
                :is-visible="kinDetailsCollapse"
                title="Next of Kin Details"
                class="custom-collapse-icon"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Next of Kin Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label">I as Next of Kin 我, 购买者之至亲</span>
                    </div>
                    <b-form-group
                      v-if="currentKinNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="I as Next of Kin"
                        rules=""
                      >
                        <b-form-input
                          id="input-default"
                          ref="kinName"
                          v-model="kinName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': kinNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeKinNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="kinNameImage"
                        v-b-modal.kin-name-modal
                        :src="kinNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentKinNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeKinNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeKinNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Address 地址"
                      label-for="kinAddress"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Address"
                        vid="kinAddress"
                        rules=""
                      >
                        <b-form-textarea
                          id="kinAddress"
                          ref="kinAddress"
                          v-model="kinAddress"
                          rows="3"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="NRIC 身份证号码"
                      label-for="kinNRIC"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="NRIC"
                        vid="kinNRIC"
                        rules=""
                      >
                        <b-form-input
                          id="kinNRIC"
                          ref="kinNRIC"
                          v-model="kinNRIC"
                          placeholder="Enter"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Contact No. 联络号码"
                      label-for="kinContact"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="kinContact"
                        vid="kinContact"
                        rules=""
                      >
                        <b-form-input
                          id="kinContact"
                          ref="kinContact"
                          v-model="kinContact"
                          placeholder="Enter"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="officeDetailsRef"
                :is-visible="officeDetailsCollapse"
                title="For office use"
                class="custom-collapse-icon"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">For office use</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Remark 备注"
                      label-for="officeRemarks"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Remark"
                        vid="officeRemarks"
                        rules=""
                      >
                        <b-form-textarea
                          id="officeRemarks"
                          ref="officeRemarks"
                          v-model="officeRemarks"
                          rows="3"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
            <div v-if="!editFormInOtherStatus">
              <b-card
                v-if="requestForm.status == 'Ready to sign'"
                class="mt-2 service-forms-card"
              >
                <template #header>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      class=""
                      size="20"
                      style="color: #fff;"
                    />
                  </b-button>
                  <h3 class="align-middle mr-auto">
                    <span class="step-class">signing required</span>
                    Ready to Sign?
                  </h3>
                </template>
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>If you're prepared to proceed, please press the button to indicate your agreement to this form.</span>
                </b-card-text>

                <!-- Button: Send Invoice -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mb-75"
                  block
                  @click="startSignProcess"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">Sign now</span>
                </b-button>
              </b-card>
              <b-card
                v-if="requestForm.status == 'Signing'"
                class="mt-2 service-forms-card"
              >
                <template #header>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      class=""
                      size="20"
                      style="color: #fff;"
                    />
                  </b-button>
                  <h3 class="align-middle mr-auto">
                    <span class="step-class">signing required</span>
                    Start signing
                  </h3>
                </template>
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>This form requires 2 signatures from:</span>
                </b-card-text>

                <app-timeline class="sign-list">
                  <app-timeline-item
                    v-if="isPurchaserSign"
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                  <app-timeline-item
                    v-if="verifiedSign"
                    title="Verified By"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="Verified By"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                </app-timeline>

                <hr class="dividerHR signing-stage__divider">

                <b-card-text
                  class="mt-2"
                >
                  <span>After submitted the signed form, the system will generate a PDF copy and email to customer.</span>
                </b-card-text>
              </b-card>

              <b-card
                v-if="requestForm.status == 'Signed and Sent'"
                class="mt-2 service-forms-card"
              >
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>This form requires 2 signatures from:</span>
                </b-card-text>

                <app-timeline class="sign-list">
                  <app-timeline-item
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    title="Verified By"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                </app-timeline>

                <hr class="dividerHR signing-stage__divider">

                <b-card-text
                  class="mt-2"
                >
                  <b-button
                    variant="outline-primary"
                    class="w-100"
                    @click="resendSignedFormModal"
                  >
                    <feather-icon
                      icon="SendIcon"
                      class="mr-50"
                      size="16"
                    />
                    <span>Resend signed form</span>
                  </b-button>
                </b-card-text>
              </b-card>
            </div>
            <div class="d-form__action-btn">
              <b-button
                variant="outline-primary"
                class="mt-2"
                style="background: #FFF;"
                :to="{ name: 'operation-service-requests-show', params: { id: serviceRequest._id } }"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  class="mr-50"
                  size="16"
                />
                <span>Return to {{ serviceRequest.stringID }}</span>
              </b-button>
              <b-button
                v-if="!editFormInOtherStatus && requestForm.status != 'To prepare'"
                variant="outline-primary"
                class="mt-2 ml-auto"
                style="background: #FFF;"
                @click="editForm"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                />
              </b-button>
            </div>
          </b-col>
          <b-col
            class="content-header-right my-1 d-md-block"
            style="position: relative;"
            md="9"
            cols="12"
          >
            <!-- Table Container Card -->
            <b-card
              no-body
              class="mb-0"
            >
              <div class="urn-form-wrapper d-form_wrapper__adjust-margin">
                <div class="orn-form-header d-flex justify-content-between mb-75">
                  <div class="d-form-logo_section" />
                  <div class="d-form-no_section">
                    <h5>
                      {{ digitalForm.formNumber }}
                    </h5>
                    <h5>
                      {{ digitalForm.revision }}
                    </h5>
                  </div>
                </div>
                <div class="urn-repository-order_section">
                  <div class="form-section-title my-2">
                    <h3
                      class="text-bold-black fs-16"
                      style="text-transform: uppercase;"
                    >
                      Request for Urn Repository For storage purpose only 骨瓮置入福位声明
                    </h3>
                  </div>
                  <div class="border p-2 my-2">
                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label-class="label-width-160"
                          label-cols="12"
                          label-cols-lg="4"
                          label-for="input-default"
                          class="align-items-center"
                        >
                          <template #label>
                            <span class="">Niche No.*:</span>
                            <br>
                            <span>福位编号</span>
                          </template>
                          <div
                            class="empty-input__text"
                            :class="{ 'bg-white': (requestForm.status != 'To prepare' || nicheNo), 'edit-mode': editFormInOtherStatus }"
                            @click="handleClick('nicheNo', 'urnDetailsRef')"
                          >
                            <p
                              v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !nicheNo"
                              class="placeholder__text"
                            />
                            <p v-else>
                              {{ nicheNo || '-' }}
                            </p>
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label-class="label-width-80"
                          label-cols="12"
                          label-cols-lg="2"
                          label-for="input-default"
                          class="align-items-center"
                        >
                          <template #label>
                            <span class="">File No.*:</span>
                            <br>
                            <span>文件号码</span>
                          </template>
                          <div
                            class="empty-input__text"
                            :class="{ 'bg-white': (requestForm.status != 'To prepare' || fileNo), 'edit-mode': editFormInOtherStatus }"
                            @click="handleClick('fileNo', 'urnDetailsRef')"
                          >
                            <p
                              v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !fileNo"
                              class="placeholder__text"
                            />
                            <p v-else>
                              {{ fileNo || '-' }}
                            </p>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="pb-0">
                      <b-col md="6">
                        <b-form-group
                          label-class="label-width-160"
                          label-cols="12"
                          label-cols-lg="4"
                          label-for="input-default"
                          class="align-items-center"
                        >
                          <template #label>
                            <span class="">Imported Urn Type*:</span>
                            <br>
                            <span>骨瓮款式</span>
                          </template>
                          <div
                            class="empty-input__text"
                            :class="{ 'bg-white': (requestForm.status != 'To prepare' || urnType), 'edit-mode': editFormInOtherStatus }"
                            @click="handleClick('urnType', 'urnDetailsRef')"
                          >
                            <p
                              v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !urnType"
                              class="placeholder__text"
                            />
                            <p v-else>
                              {{ urnType || '-' }}
                            </p>
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label-class="label-width-80"
                          label-cols="12"
                          label-cols-lg="2"
                          label-for="input-default"
                          class="align-items-center"
                        >
                          <template #label>
                            <span class="">Unit*:</span>
                            <br>
                            <span>数量</span>
                          </template>
                          <div
                            class="empty-input__text"
                            :class="{ 'bg-white': (requestForm.status != 'To prepare' || unit), 'edit-mode': editFormInOtherStatus }"
                            @click="handleClick('unit', 'urnDetailsRef')"
                          >
                            <p
                              v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !unit"
                              class="placeholder__text"
                            />
                            <p v-else>
                              {{ unit || '-' }}
                            </p>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-class="label-width-320"
                        label-cols="12"
                        label-cols-lg="4"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span class="">I as Purchaser/Authorized Representative*,</span>
                          <br>
                          <span>我, 购买者/购买者之委托人</span>
                        </template>
                        <div
                          v-if="currentCustomerNameType == 'input'"
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerName), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('customerName', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerName"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ customerName || '-' }}
                          </p>
                        </div>
                        <div
                          v-else
                          :class="{ 'signed': customerNameImage }"
                          style="border-bottom: 1px solid #000"
                        >
                          <b-img
                            v-if="customerNameImage"
                            :src="customerNameImage"
                            class="w-100 signature-image"
                            alt="sign"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="1"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span class="">of*</span>
                          <br>
                          <span>的</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerAddress), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('customerAddress', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerAddress"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ customerAddress || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-class="label-width-100"
                        label-cols="12"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span class="">NRIC*:</span>
                          <br>
                          <span>身份证号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerNRIC), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('customerNRIC', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerNRIC"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ customerNRIC || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-class="label-width-100"
                        label-cols="12"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span class="">Contact No.</span>
                          <br>
                          <span>联络号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerContact), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('customerContact', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerContact"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ customerContact || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-class="label-width-140"
                        label-cols="12"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span class="">I as Next of Kin*,</span>
                          <br>
                          <span>我, 购买者之至亲</span>
                        </template>
                        <div
                          v-if="currentKinNameType == 'input'"
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinName), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('kinName', 'kinDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinName"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ kinName || '-' }}
                          </p>
                        </div>
                        <div
                          v-else
                          :class="{ 'signed': kinNameImage }"
                          style="border-bottom: 1px solid #000"
                        >
                          <b-img
                            v-if="kinNameImage"
                            :src="kinNameImage"
                            class="w-100 signature-image"
                            alt="sign"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="1"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span class="">of*</span>
                          <br>
                          <span>的</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinAddress), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('kinAddress', 'kinDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinAddress"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ kinAddress || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-class="label-width-100"
                        label-cols="12"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span class="">NRIC*:</span>
                          <br>
                          <span>身份证号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinNRIC), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('kinNRIC', 'kinDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinNRIC"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ kinNRIC || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-class="label-width-100"
                        label-cols="12"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span class="">Contact No.</span>
                          <br>
                          <span>联络号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinContact), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('kinContact', 'kinDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinContact"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ kinContact || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="text-block">
                    <p>is the purchaser / authorized representative / next of kin of the above niche seek for your consent to repose the aforesaid urn (“the Said Urn”) without containing cremated ask in the above mentioned Niche. </p>
                    <p class="black-font weight-400">
                      本人乃新加坡富贵山庄福位购买者/购买者之至亲/购买者之委托人（本人身分证号，文件号码，骨瓮类别与骨瓮数量如附表），请协助将如数骨瓮置入所购福位。
                    </p>
                    <p>I shall indemnity you and keep you fully and competely indemnifield against all claims, liabilities demand actions, proceedings costs and expense that you may suffer arising from your acceptance of the Said Urn.</p>
                  </div>
                </div>
                <div class="urn-form-terms-and-condition border p-1">
                  <h3
                    class="text-center fs-14 text-bold-black"
                    style="text-transform: uppercase;"
                  >
                    Terms and conditions 规则与条件
                  </h3>
                  <ul class="form-text-block-list">
                    <li>The said urn is reposed in the Said Niche at no extra costs.</li>
                    <li>The Company is not responsible and liable for any loss/ damage to the Said Urn.</li>
                    <li>The Said Urn shall be reposed in its rightful respective niche. Under no Circumstances the Said Urn is permitted to be reposed in any other niche notwithstanding consent being granted by another owner of different niche on whatsoever reasons. </li>
                    <li>The Purchaser and the owner of the Said Urn is the same person.</li>
                    <li>The Said Urn will be removed upon the exercise of the Said Urn and the same is permitted to be reposed back after the funeral services has completed.</li>
                    <li>You have the sole discretion as to whether the Said Urn containing the cremated ash is reposed back to the Said Niche or otherwise.</li>
                  </ul>
                  <h3 class="text-center weight-400 fs-14 black-font">
                    本人同意遵照此声明所载之上述规则与条件
                  </h3>
                </div>
                <h3 class="text-center black-font weight-400 fs-14 mt-1">
                  备注： 如对条规的中文译本有任何诠释上之争议，将以英文译本为解释标准
                </h3>
                <div class="border mt-2">
                  <b-row>
                    <b-col
                      md="6"
                      class="px-2 py-2 border-right border-right-none"
                    >
                      <p class="d-form-signature-title">Purchaser / Authorised Representative Signatory & Date
                        <br>
                        <span>购者/受委托领瓮人签名和日期</span>
                      </p>
                      <div
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-modal.purchaser-sign-modal
                        class="form-signature-box"
                        style="width: 90%"
                        :class="{ 'signed': isPurchaserSign, 'edit-mode': editFormInOtherStatus }"
                        :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                      >
                        <div class="form-signature-content">
                          <span
                            v-if="!isPurchaserSign"
                            class="form-signature-text"
                          >
                            Sign here
                          </span>
                          <b-img
                            v-else
                            :src="purchaserSign"
                            alt="authorized-sign"
                          />
                        </div>
                      </div>
                      <div class="signature-date-block">
                        <p>Name 姓名: {{ customerName }}</p>
                        <p v-if="purchaserSignDate">
                          Date 日期: {{ dateFormatWithTime(purchaserSignDate) }}
                        </p>
                        <p v-else>
                          Date 日期:
                        </p>
                      </div>
                    </b-col>
                    <b-col
                      md="6"
                      class="mt-2 px-1"
                      :class="{ 'px-1-5': isMobile() }"
                    >
                      <b-form-group
                        label="Remark 备注:"
                        label-for="input-default"
                        class="mr-1 remarks"
                        label-class="fs-12 weight-500"
                      >
                        <div
                          class="empty-input__textarea mh-105"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || officeRemarks), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('officeRemarks', 'officeDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !officeRemarks"
                            class="placeholder__textarea"
                          />
                          <p v-else>
                            {{ officeRemarks || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <div class="border mt-4">
                  <h5 class="border-bottom black-font weight-400 fs-14 text-center py-1 text-uppercase">
                    For office use 公务用栏
                  </h5>
                  <b-row>
                    <b-col
                      md="6"
                      class="px-2 py-2"
                    >
                      <p class="d-form-signature-title">
                        Verified By 确认人员
                      </p>
                      <div
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-modal.verified-sign-modal
                        class="form-signature-box"
                        :class="{ 'signed': verifiedSign, 'edit-mode': editFormInOtherStatus }"
                        :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                      >
                        <div class="form-signature-content">
                          <span
                            v-if="!verifiedSign"
                            class="form-signature-text"
                          >
                            Sign here
                          </span>
                          <b-img
                            v-else
                            :src="verifiedSignSrc"
                            alt="authorized-sign"
                          />
                        </div>
                      </div>
                      <div class="signature-date-block">
                        <p>Name 姓名: {{ verifiedSignName }}</p>
                        <p v-if="verifiedSignDate">
                          Date 日期: {{ dateFormatWithTime(verifiedSignDate) }}
                        </p>
                        <p v-else>
                          Date 日期:
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <div
          v-if="requestForm.status == 'To prepare'"
          class="d-form_action-btn_group wrap-border save-nav"
        >
          <p
            v-if="isMobile()"
            class="margin-left-20 mt-50 mb-50 d-form_action-title"
          >
            <span>Urn Storage Request Form</span>
          </p>
          <b-nav
            :class="{'d-form_action-btn_tablet': isMobile()}"
            class="padding-left-right-20"
          >
            <li
              v-if="!isMobile()"
              class="nav-item"
            >
              <span>Urn Storage Request Form</span>
            </li>
            <li
              class="nav-item"
              :class="{'d-form_action-btn__mob-li': isMobile(), 'ml-auto margin-right-5': !isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="outline-primary"
                @click="submitSaveForm('saveOnly')"
              >
                Save changes
              </b-button>
            </li>
            <li
              class="nav-item d-form_action-second_btn"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                @click="makeFormNotRequired"
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span class="align-middle">Mark as Not Required</span>
              </b-button>
            </li>
            <li
              class="nav-item d-form_action-third_btn"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                @click="submitSaveForm('readyToSign')"
              >
                <feather-icon
                  icon="FeatherIcon"
                  class="mr-50"
                />
                <span class="align-middle">Mark as Ready to Sign</span>
              </b-button>
            </li>
          </b-nav>
        </div>

        <div
          v-if="editFormInOtherStatus"
          class="d-form_action-btn_group wrap-border save-nav"
        >
          <p
            v-if="isMobile()"
            class="margin-left-20 mt-50 mb-50 d-form_action-title"
          >
            <span>Editing Urn Storage Request Form</span>
          </p>
          <b-nav
            class="padding-left-right-20"
            :class="{'d-form_action-btn_tablet': isMobile()}"
          >
            <li
              v-if="!isMobile()"
              class="nav-item"
            >
              <span>Editing Urn Storage Request Form</span>
            </li>
            <li
              :class="{'ml-auto margin-right-5': !isMobile(), 'mob__cancel-btn': isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="outline-primary"
                class="action-cancel_btn"
                @click="editFormInOtherStatus = false; makeFormValues()"
              >
                Cancel Editing
              </b-button>
            </li>
            <li
              class="nav-item"
              :class="{'d-form_action-editing__btn': isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                class="action-done_btn"
                @click="submitSaveFormConfirmation()"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                <span class="align-middle">Done</span>
              </b-button>
            </li>
          </b-nav>
        </div>
      </b-form>
    </validation-observer>
    <!-- authorized signature modal -->
    <b-modal
      id="purchaser-sign-modal"
      ref="purchaser-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Authorized Signature"
      @ok="closePurchaserSignModal"
      @cancel="closePurchaserSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Purchaser / Authorized Representative Signatory & Date</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearPurchaserSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePurchaserSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ authorized signature modal -->
    <!-- verified by signature modal -->
    <b-modal
      id="verified-sign-modal"
      ref="verified-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Verified By"
      @ok="closeVerifiedSignModal"
      @cancel="closeVerifiedSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Verified By</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearVerifiedSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveVerifiedSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ verified by signature modal -->
    <!-- customer name modal -->
    <b-modal
      id="customer-name-modal"
      ref="customer-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeCustomerNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="customerSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="customerNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearCustomerName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveCustomerName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- customer name -->
    <!-- kin name modal -->
    <b-modal
      id="kin-name-modal"
      ref="kin-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeKinNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="kinSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="kinNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearKinName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveKinName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- kin name -->

    <!-- submit signed form -->
    <b-modal
      id="submit-form-modal"
      ref="submit-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this signed form?"
      @ok="submitSignedForm"
      @cancel="closeSubmitFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="+65 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="resend-form-modal"
      ref="resend-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Resend this form?"
      @ok="resendSignedForm"
      @cancel="closeResendFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="+65 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to Form
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="edit-form-modal"
      ref="edit-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this edited form?"
      @ok="submitSaveForm('edited', true)"
      @cancel="cancelEditing"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="+65 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BImg, BButton, BNav, BForm, BFormTextarea, BTab, BTabs,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { required } from '@validations'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContentCustom from '../../ToastificationContentCustom.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BButton,
    BNav,
    BForm,
    BFormTextarea,
    BTab,
    BTabs,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentCustom,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      verifiedSign: false,
      verifiedSignSrc: '',
      verifiedSignDate: '',
      verifiedSignName: '',
      customerNameImageError: false,
      kinNameImageError: false,
      purchaserDetailsCollapse: false,
      kinDetailsCollapse: false,
      officeDetailsCollapse: false,
      urnCollapse: true,
      editFormInOtherStatus: false,
      tabActive: 'email',
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', maxDate: 'today', disableMobile: true,
      },
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      currentCustomerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerAddress: '',
      customerContact: '',
      customerNRIC: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinAddress: '',
      kinContact: '',
      kinNRIC: '',
      fileNo: '',
      nicheNo: '',
      urnType: '',
      unit: '',
      officeRemarks: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      existingVerifiedSignSrcObject: {},
      existingPurchaserSignObject: {},
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Type your message here',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Type your message here',
      },
      required,
    }
  },
  created() {
    this.makeFormValues()
    this.customerMobileVal = this.requestForm.customerContact
    this.customerEmailVal = this.requestForm.customerEmail
  },
  methods: {
    makeFormValues() {
      this.fileNo = this.serviceRequest.fileNo
      this.nicheNo = this.serviceRequest.serviceForm.deceasedNiche
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.currentCustomerNameType = 'canvas'
        } else {
          this.currentCustomerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.customerNRIC = this.serviceRequest.formValues.find(o => o.key === 'customerNRIC')?.value
      this.customerContact = this.serviceRequest.formValues.find(o => o.key === 'customerContact')?.value
      this.customerAddress = this.serviceRequest.formValues.find(o => o.key === 'customerAddress')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.currentKinNameType = 'canvas'
        } else {
          this.currentKinNameType = 'input'
          this.kinName = kinNameObject.value
        }
      }
      this.kinNRIC = this.serviceRequest.formValues.find(o => o.key === 'kinNRIC')?.value
      this.kinContact = this.serviceRequest.formValues.find(o => o.key === 'kinContact')?.value
      this.kinAddress = this.serviceRequest.formValues.find(o => o.key === 'kinAddress')?.value
      if (this.requestForm.values.length) {
        const urnTypeObject = this.requestForm.values.find(o => o.key === 'urnType')
        if (urnTypeObject) {
          this.urnType = urnTypeObject.value
        }
        const unitObject = this.requestForm.values.find(o => o.key === 'unit')
        if (unitObject) {
          this.unit = unitObject.value
        }
        const officeRemarksObject = this.requestForm.values.find(o => o.key === 'officeRemarks')
        if (officeRemarksObject) {
          this.existingOfficeRemarksObject = officeRemarksObject
          this.officeRemarks = officeRemarksObject.value
        }
        const purchaserSignObject = this.requestForm.values.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject) {
          this.existingPurchaserSignObject = purchaserSignObject
          this.purchaserSign = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
        const verifiedSignSrcObject = this.requestForm.values.find(o => o.key === 'verifiedSignSrc')
        if (verifiedSignSrcObject) {
          this.existingVerifiedSignSrcObject = verifiedSignSrcObject
          this.verifiedSignSrc = verifiedSignSrcObject.value
          this.verifiedSign = true
          this.verifiedSignDate = verifiedSignSrcObject.date
          this.verifiedSignName = verifiedSignSrcObject.name
        }
      }
    },
    startSignProcess() {
      const finalStatus = 'Signing'
      const formData = new FormData()
      formData.append('status', finalStatus)
      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editForm() {
      if (this.requestForm.status === 'Not required') {
        const finalStatus = 'To prepare'
        const formData = new FormData()
        formData.append('status', finalStatus)
        this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              await this.$emit('update:requestForm', response.data.data)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.editFormInOtherStatus = true
      }
    },
    makeFormNotRequired() {
      this.$swal({
        title: 'Are you sure you want to ‘Mark as Not Required’ for this form?',
        html: 'The form status will change to ‘Not Required’, all information you have keyed in will be cleared.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: "Yes, I'm sure",
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('status', 'Not required')
            this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(async response => {
                if (response.data.data) {
                  await this.$emit('update:requestForm', response.data.data)
                  this.makeFormValues()
                  this.$swal({
                    title: 'Form Has Been Marked as Not Required',
                    // html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message || '',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    closePurchaserSignModal() {
      this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
    },
    clearPurchaserSign() {
      this.$refs.signaturePad.clearSignature()
    },
    savePurchaserSign() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
        const purchaserSignObj = {
          key: 'purchaserSign',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          this.purchaserSign = data
          this.isPurchaserSign = true
          purchaserSignObj.date = new Date()
          this.existingPurchaserSignObject = purchaserSignObj
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.verifiedSign) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(purchaserSignObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    closeVerifiedSignModal() {
      this.$root.$emit('bv::hide::modal', 'verified-sign-modal', '')
    },
    clearVerifiedSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveVerifiedSign() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'verified-sign-modal', '')
        const verifiedSignObj = {
          key: 'verifiedSignSrc',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          this.verifiedSignSrc = data
          this.verifiedSign = true
          verifiedSignObj.date = new Date()
          verifiedSignObj.name = this.user.name
          verifiedSignObj.admin = this.user._id
          this.existingVerifiedSignSrcObject = verifiedSignObj
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.isPurchaserSign) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(verifiedSignObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    closeCustomerNameModal() {
      this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
      if (!this.customerNameImage) {
        this.currentCustomerNameType = 'input'
      }
    },
    closeKinNameModal() {
      this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
      if (!this.kinNameImage) {
        this.currentKinNameType = 'input'
      }
    },
    submitSignedForm(option = null) {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (option === 'edited') {
        formData.append('edited', 'yes')
      }
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.requestForm.formID.name)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)

      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/generate-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
            this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')

            this.makeFormValues()
            this.$toast({
              component: ToastificationContentCustom,
              props: {
                title: 'PDF is ready!',
                text: response.data.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resendSignedFormModal() {
      if (this.requestForm.pdfs.length) {
        this.$root.$emit('bv::show::modal', 'resend-form-modal', '')
      } else {
        this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
      }
    },
    resendSignedForm() {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.requestForm.formID.name)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)

      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/resend-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.$toast({
            component: ToastificationContentCustom,
            props: {
              title: 'PDF is ready!',
              text: response.data.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeSubmitFormModal() {
      this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')
    },
    closeResendFormModal() {
      this.$root.$emit('bv::hide::modal', 'resend-form-modal', '')
    },
    clearCustomerName() {
      this.customerNameImage = ''
      this.$refs.customerSignaturePad.clearSignature()
    },
    clearKinName() {
      this.kinNameImage = ''
      this.$refs.kinSignaturePad.clearSignature()
    },
    saveCustomerName() {
      const { data } = this.$refs.customerSignaturePad.saveSignature()
      if (data) {
        this.customerNameImage = data
        this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
        this.currentCustomerNameType = 'canvas'
      }
    },
    saveKinName() {
      const { data } = this.$refs.kinSignaturePad.saveSignature()
      if (data) {
        this.kinNameImage = data
        this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
        this.currentKinNameType = 'canvas'
      }
    },
    async handleClick(field, collapse) {
      if (this.requestForm.status === 'To prepare' || this.editFormInOtherStatus) {
        await this.$refs[collapse].updateVisible(true)
        this.$refs[field].focus()
      }
    },
    changeCustomerNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.customerNameImage = ''
              this.currentCustomerNameType = type
            }
          })
      } else {
        this.currentCustomerNameType = type
        this.$root.$emit('bv::show::modal', 'customer-name-modal', '')
      }
    },
    changeKinNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.kinNameImage = ''
              this.currentKinNameType = type
            }
          })
      } else {
        this.currentKinNameType = type
        this.$root.$emit('bv::show::modal', 'kin-name-modal', '')
      }
    },
    submitSaveFormConfirmation() {
      if (this.requestForm.status === 'Signed and Sent') {
        this.$root.$emit('bv::show::modal', 'edit-form-modal', '')
      } else {
        this.submitSaveForm()
      }
    },
    cancelEditing() {
      this.$root.$emit('bv::hide::modal', 'edit-form-modal', '')
      // this.editFormInOtherStatus = false
      // this.makeFormValues()
    },
    submitSaveForm(option = null, submit = false) {
      this.$refs.serviceRequestFormSave.validate().then(success => {
        let hasCustomerImage = true
        if (this.currentCustomerNameType === 'canvas' && !this.customerNameImage) {
          hasCustomerImage = false
          this.customerNameImageError = true
        }
        // let hasKinImage = true
        // if (this.currentKinNameType === 'canvas' && !this.kinNameImage) {
        //   hasKinImage = false
        //   this.kinNameImageError = true
        // }
        if ((option && option === 'saveOnly') || (success && hasCustomerImage)) {
          const formData = new FormData()
          if (option && option === 'readyToSign') {
            formData.append('readyToSign', true)
          }
          const valueArray = []
          let srValueArray = this.serviceRequest.formValues
          const sfValueObject = {}
          const srValueObject = {}
          const customerNameObj = {
            key: 'customerName',
          }
          if (this.currentCustomerNameType === 'input') {
            customerNameObj.value = this.customerName
          } else {
            customerNameObj.value = this.customerNameImage
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerNameObj.key).concat(customerNameObj)

          const customerNRICObj = {
            key: 'customerNRIC',
            value: this.customerNRIC,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerNRICObj.key).concat(customerNRICObj)

          const customerAddressObj = {
            key: 'customerAddress',
            value: this.customerAddress,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerAddressObj.key).concat(customerAddressObj)

          const customerContactObj = {
            key: 'customerContact',
            value: this.customerContact,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerContactObj.key).concat(customerContactObj)

          const kinNameObj = {
            key: 'kinName',
          }
          if (this.currentKinNameType === 'input') {
            kinNameObj.value = this.kinName
          } else {
            kinNameObj.value = this.kinNameImage
          }
          srValueArray = srValueArray.filter(obj => obj.key !== kinNameObj.key).concat(kinNameObj)

          const kinNRICObj = {
            key: 'kinNRIC',
            value: this.kinNRIC,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== kinNRICObj.key).concat(kinNRICObj)

          const kinAddressObj = {
            key: 'kinAddress',
            value: this.kinAddress,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== kinAddressObj.key).concat(kinAddressObj)

          const kinContactObj = {
            key: 'kinContact',
            value: this.kinContact,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== kinContactObj.key).concat(kinContactObj)

          srValueObject.fileNo = this.fileNo

          const urnTypeObj = {
            key: 'urnType',
            value: this.urnType,
          }
          valueArray.push(urnTypeObj)

          const unitObj = {
            key: 'unit',
            value: this.unit,
          }
          valueArray.push(unitObj)

          const officeRemarksObj = {
            key: 'officeRemarks',
            value: this.officeRemarks,
          }
          valueArray.push(officeRemarksObj)

          let verifiedSignVal = 0
          let purchaserSignVal = 0

          if (this.existingVerifiedSignSrcObject.key) {
            verifiedSignVal = 1
            valueArray.push(this.existingVerifiedSignSrcObject)
          }
          if (this.existingPurchaserSignObject.key) {
            purchaserSignVal = 1
            valueArray.push(this.existingPurchaserSignObject)
          }

          let allSigned = false
          if (verifiedSignVal && purchaserSignVal) {
            allSigned = true
          }

          // let valueChanged = false
          // if (this.requestForm.status === 'Signed and Sent') {
          //   const difference = valueArray.filter(({ value: id1 }) => !this.requestForm.values.some(({ value: id2 }) => id2 === id1))
          //   if (difference.length) {
          //     const otherValueChanged = difference.find(o => !['installationDate', 'installationTime'].includes(o.key))
          //     if (otherValueChanged) {
          //       valueChanged = true
          //     }
          //   }
          // }

          formData.append('values', JSON.stringify(valueArray))
          formData.append('srValues', JSON.stringify(srValueArray))
          formData.append('sfValues', JSON.stringify(sfValueObject))
          formData.append('srValueObject', JSON.stringify(srValueObject))
          formData.append('allSigned', allSigned)
          formData.append('currentStatus', this.requestForm.status)
          formData.append('formName', this.requestForm.formID.name)
          formData.append('serviceRequest', this.serviceRequest._id)
          formData.append('serviceForm', this.serviceRequest.serviceForm._id)
          formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                await this.$emit('update:serviceRequest', response.data.sr)
                this.makeFormValues()
                this.editFormInOtherStatus = false
                if (option && option === 'edited' && response.data.data.status === 'Signed and Sent' && submit) {
                  this.submitSignedForm('edited')
                } else if (response.data.data.status === 'Signing' && allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Form Data Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          const items = document.getElementsByClassName('is-invalid')
          if (items && items.length) {
            // window.scrollTo({
            //   top: items[0].offsetHeight + 100,
            //   behavior: 'smooth',
            // })
            items[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }
        }
      })
    },
  },
}
</script>
