<template>
  <div>
    <validation-observer
      ref="serviceRequestFormSave"
    >
      <b-form @submit.prevent="submitSaveForm">
        <b-row
          class="content-header v-sticky-sidebar-container service_request_task collapse__form-wrapper"
          :class="{ 'white-background': !editFormInOtherStatus, 'green-background': editFormInOtherStatus }"
          style="border-radius: 6px;"
        >
          <!-- Content Left -->
          <b-col
            class="content-header-left my-1 top-info-right"
            cols="12"
            md="3"
            lg="3"
          >
            <div
              class="user_block ac_type"
              style="float: none;"
            >
              <div class="user_avatar" />
              <div class="user_info">
                <h6>Status</h6>
                <h5 class="">
                  {{ requestForm.status }}
                </h5>
              </div>
            </div>

            <b-card
              v-if="editFormInOtherStatus"
              class="mt-2 service-forms-card success-card"
            >
              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class=""
                    size="20"
                    style="color: #fff;"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  <span class="step-class">you're now</span>
                  Editing this form
                </h3>
              </template>
              <b-card-text
                class="signing-stage__title"
              >
                <span>After submitting the signed form, the system will generate a signed PDF copy and email to customer.</span>
              </b-card-text>
            </b-card>

            <app-collapse
              v-if="requestForm.status == 'To prepare' || editFormInOtherStatus"
              accordion
              class="mt-2 service-form-collapse"
            >
              <app-collapse-item
                ref="purchaserDetailsRef"
                title="Purchaser Details"
                class="custom-collapse-icon"
                :is-visible="purchaserCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Purchaser Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label label-required-star">Purchaser Name 购买者</span>
                    </div>
                    <b-form-group
                      v-if="currentCustomerNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Purchaser Name"
                        rules="required"
                      >
                        <b-form-input
                          id="input-default"
                          ref="customerName"
                          v-model="customerName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                              border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': customerNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeCustomerNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="customerNameImage"
                        v-b-modal.customer-name-modal
                        :src="customerNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentCustomerNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeCustomerNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeCustomerNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="NRIC 身分证号码"
                      label-for="customerNRIC"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="NRIC"
                        vid="customerNRIC"
                        rules="required"
                      >
                        <b-form-input
                          id="customerNRIC"
                          ref="customerNRIC"
                          v-model="customerNRIC"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Contact No. 联络电话"
                      label-for="customerContact"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Contact No."
                        vid="customerContact"
                        rules=""
                      >
                        <b-form-input
                          id="customerContact"
                          ref="customerContact"
                          v-model="customerContact"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="kinDetailsRef"
                title="Next-of-Kin Details"
                class="custom-collapse-icon"
                :is-visible="kinCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Next-of-Kin Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label">Next of Kin Name 至亲姓名</span>
                    </div>
                    <b-form-group
                      v-if="currentKinNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Next of Kin Name"
                        rules=""
                      >
                        <b-form-input
                          id="input-default"
                          ref="kinName"
                          v-model="kinName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                              border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': kinNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeKinNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="kinNameImage"
                        v-b-modal.kin-name-modal
                        :src="kinNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentKinNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeKinNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeKinNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="NRIC 身分证号码"
                      label-for="kinNRIC"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="NRIC"
                        vid="kinNRIC"
                        rules=""
                      >
                        <b-form-input
                          id="kinNRIC"
                          ref="kinNRIC"
                          v-model="kinNRIC"
                          placeholder="Enter"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Contact No. 联络电话"
                      label-for="kinContact"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Contact No."
                        vid="kinContact"
                        rules=""
                      >
                        <b-form-input
                          id="kinContact"
                          ref="kinContact"
                          v-model="kinContact"
                          placeholder="Enter"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="nicheDetailsRef"
                title="Niche and Pedestal Details"
                class="custom-collapse-icon"
                :is-visible="nicheCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Niche and Pedestal Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Niche 福位编号"
                      label-for="nicheNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Niche"
                        vid="nicheNo"
                        rules=""
                      >
                        <b-form-input
                          id="nicheNo"
                          ref="nicheNo"
                          v-model="nicheNo"
                          placeholder="Enter"
                          readonly
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Pedestal No. 牌位编号"
                      label-for="pedestalNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Pedestal No."
                        vid="pedestalNo"
                        rules=""
                      >
                        <b-form-input
                          id="pedestalNo"
                          ref="pedestalNo"
                          v-model="pedestalNo"
                          placeholder="Enter"
                          readonly
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="particularDetailsRef"
                title="Particulars of Deceased"
                class="custom-collapse-icon"
                :is-visible="particularCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Particulars of Deceased</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label label-required-star">Name of Deceased 先人名讳</span>
                    </div>
                    <b-form-group
                      v-if="currentDeceasedNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Name of Deceased"
                        rules=""
                      >
                        <b-form-input
                          id="input-default"
                          ref="deceasedName"
                          v-model="deceasedName"
                          placeholder="Enter"
                          readonly
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': deceasedNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeDeceasedNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="deceasedNameImage"
                        v-b-modal.deceased-name-modal
                        :src="deceasedNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentDeceasedNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeDeceasedNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeDeceasedNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Gender"
                      label-for="gender"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="gender"
                        vid="gender"
                        rules=""
                      >
                        <b-form-radio-group
                          id="gender"
                          ref="gender"
                          v-model="gender"
                          name="gender"
                          disabled
                          class="form-custom-input-checkbox"
                          style="pointer-events: none;"
                          :options="genderOptions"
                          @input="genderVal = [gender]"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Age 年龄"
                      label-class="field-label label-required-star"
                      label-for="age"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Age"
                        vid="age"
                        rules=""
                      >
                        <b-form-input
                          id="age"
                          ref="age"
                          v-model="age"
                          placeholder="Enter"
                          readonly
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <div class="p-1 mt-50">
                    <span class="label-required-star">Departed Date 逝世日期</span>
                  </div>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="departedDay"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Departed Day"
                        vid="departedDay"
                        rules="required"
                      >
                        <b-form-input
                          id="departedDay"
                          ref="departedDay"
                          v-model="departedDay"
                          placeholder="Day 日"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="departedMonth"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Departed Month"
                        vid="departedMonth"
                        rules="required"
                      >
                        <b-form-input
                          id="departedMonth"
                          ref="departedMonth"
                          v-model="departedMonth"
                          placeholder="Month 月"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="departedYear"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Departed Year"
                        vid="departedYear"
                        rules="required"
                      >
                        <b-form-input
                          id="departedYear"
                          ref="departedYear"
                          v-model="departedYear"
                          placeholder="Year 年"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Relationship 关系"
                      label-class="field-label label-required-star"
                      label-for="relationship"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Relationship"
                        vid="relationship"
                        rules="required"
                      >
                        <b-form-input
                          id="relationship"
                          ref="relationship"
                          v-model="relationship"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="serviceDetailsRef"
                title="Service Record"
                class="custom-collapse-icon"
                :is-visible="serviceCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Service Record</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Allocated Lot 灵位阁与编号"
                      label-class="field-label"
                      label-for="age"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Allocated Lot"
                        vid="allocatedLot"
                        rules=""
                      >
                        <b-form-input
                          id="allocatedLot"
                          ref="allocatedLot"
                          v-model="allocatedLot"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <div class="p-1 mt-50">
                    <span class="text-bold-black">An Ling 安灵</span>
                  </div>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Date 日期"
                      label-for="anlingDay"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="anlingDay"
                        vid="anlingDay"
                        rules=""
                      >
                        <b-form-input
                          id="anlingDay"
                          ref="anlingDay"
                          v-model="anlingDay"
                          placeholder="Day 日"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="anlingMonth"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="anlingMonth"
                        vid="anlingMonth"
                        rules=""
                      >
                        <b-form-input
                          id="anlingMonth"
                          ref="anlingMonth"
                          v-model="anlingMonth"
                          placeholder="Month 月"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="anlingYear"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="anlingYear"
                        vid="anlingYear"
                        rules=""
                      >
                        <b-form-input
                          id="anlingYear"
                          ref="anlingYear"
                          v-model="anlingYear"
                          placeholder="Year 年"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <div class="p-1 mt-50">
                    <span class="text-bold-black">Chai Ling 拆灵</span>
                  </div>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Date 日期"
                      label-for="chaiLingDay"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="chaiLingDay"
                        vid="chaiLingDay"
                        rules=""
                      >
                        <b-form-input
                          id="chaiLingDay"
                          ref="chaiLingDay"
                          v-model="chaiLingDay"
                          placeholder="Day 日"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="chaiLingMonth"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="chaiLingMonth"
                        vid="chaiLingMonth"
                        rules=""
                      >
                        <b-form-input
                          id="chaiLingMonth"
                          ref="chaiLingMonth"
                          v-model="chaiLingMonth"
                          placeholder="Month 月"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="chaiLingYear"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="chaiLingYear"
                        vid="chaiLingYear"
                        rules=""
                      >
                        <b-form-input
                          id="chaiLingYear"
                          ref="chaiLingYear"
                          v-model="chaiLingYear"
                          placeholder="Year 年"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="49 Days 尾七"
                      label-for="chaiLing49Day"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="chaiLing49Day"
                        vid="chaiLing49Day"
                        rules=""
                      >
                        <b-form-input
                          id="chaiLing49Day"
                          ref="chaiLing49Day"
                          v-model="chaiLing49Day"
                          placeholder="Day 日"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="chaiLing49Month"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="chaiLing49Month"
                        vid="chaiLing49Month"
                        rules=""
                      >
                        <b-form-input
                          id="chaiLing49Month"
                          ref="chaiLing49Month"
                          v-model="chaiLing49Month"
                          placeholder="Month 月"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="chaiLing49Year"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="chaiLing49Year"
                        vid="chaiLing49Year"
                        rules=""
                      >
                        <b-form-input
                          id="chaiLing49Year"
                          ref="chaiLing49Year"
                          v-model="chaiLing49Year"
                          placeholder="Year 年"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="100 Days 百日"
                      label-for="chaiLing100Day"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="chaiLing100Day"
                        vid="chaiLing100Day"
                        rules=""
                      >
                        <b-form-input
                          id="chaiLing100Day"
                          ref="chaiLing100Day"
                          v-model="chaiLing100Day"
                          placeholder="Day 日"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="chaiLing100Month"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="chaiLing100Month"
                        vid="chaiLing100Month"
                        rules=""
                      >
                        <b-form-input
                          id="chaiLing100Month"
                          ref="chaiLing100Month"
                          v-model="chaiLing100Month"
                          placeholder="Month 月"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="chaiLing100Year"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="chaiLing100Year"
                        vid="chaiLing100Year"
                        rules=""
                      >
                        <b-form-input
                          id="chaiLing100Year"
                          ref="chaiLing100Year"
                          v-model="chaiLing100Year"
                          placeholder="Year 年"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="othersDetailsRef"
                :is-visible="otherDetailsCollapse"
                title="Other Requests"
                class="custom-collapse-icon m-0"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Other Requests</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="remarks"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="remarks"
                        vid="remarks"
                        rules=""
                      >
                        <b-form-textarea
                          id="remarks"
                          ref="remarks"
                          v-model="remarks"
                          rows="3"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="officeDetailsRef"
                title="For office use"
                class="custom-collapse-icon"
                :is-visible="officeCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">For office use</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Agent Name"
                      label-for="agentName"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Agent Name"
                        vid="agentName"
                        rules=""
                      >
                        <b-form-input
                          id="agentName"
                          ref="agentName"
                          v-model="agentName"
                          placeholder="Enter"
                          readonly
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Agent Contact No."
                      label-for="agentContact"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Agent Contact No."
                        vid="agentContact"
                        rules=""
                      >
                        <b-form-input
                          id="agentContact"
                          ref="agentContact"
                          v-model="agentContact"
                          placeholder="Enter"
                          readonly
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
            <div v-if="!editFormInOtherStatus">
              <b-card
                v-if="requestForm.status == 'Ready to sign'"
                class="mt-2 service-forms-card"
              >
                <template #header>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      class=""
                      size="20"
                      style="color: #fff;"
                    />
                  </b-button>
                  <h3 class="align-middle mr-auto">
                    <span class="step-class">signing required</span>
                    Ready to Sign?
                  </h3>
                </template>
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>If you're prepared to proceed, please press the button to indicate your agreement to this form.</span>
                </b-card-text>

                <!-- Button: Send Invoice -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mb-75"
                  block
                  @click="startSignProcess"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">Sign now</span>
                </b-button>
              </b-card>
              <b-card
                v-if="requestForm.status == 'Signing'"
                class="mt-2 service-forms-card"
              >
                <template #header>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      class=""
                      size="20"
                      style="color: #fff;"
                    />
                  </b-button>
                  <h3 class="align-middle mr-auto">
                    <span class="step-class">signing required</span>
                    Start signing
                  </h3>
                </template>
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>This form requires 2 signatures from:</span>
                </b-card-text>

                <app-timeline class="sign-list">
                  <app-timeline-item
                    v-if="isPurchaserSignFirst || isPurchaserSignSecond"
                    title="Purchaser / Authorized Representative"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="Purchaser / Authorized Representative"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                  <app-timeline-item
                    v-if="verifiedSign"
                    title="Service Staff"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="Service Staff"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                </app-timeline>

                <hr class="dividerHR signing-stage__divider">

                <b-card-text
                  class="mt-2"
                >
                  <span>After submitted the signed form, the system will generate a PDF copy and email to customer.</span>
                </b-card-text>
              </b-card>

              <b-card
                v-if="requestForm.status == 'Signed and Sent'"
                class="mt-2 service-forms-card"
              >
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>This form requires 2 signatures from:</span>
                </b-card-text>

                <app-timeline class="sign-list">
                  <app-timeline-item
                    title="Purchaser / Authorized Representative"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    title="Service Staff"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                </app-timeline>

                <hr class="dividerHR signing-stage__divider">

                <b-card-text
                  class="mt-2"
                >
                  <b-button
                    variant="outline-primary"
                    class="w-100"
                    @click="resendSignedFormModal"
                  >
                    <feather-icon
                      icon="SendIcon"
                      class="mr-50"
                      size="16"
                    />
                    <span>Resend signed form</span>
                  </b-button>
                </b-card-text>
              </b-card>
            </div>
            <div>
              <div class="d-form__action-btn">
                <b-button
                  variant="outline-primary"
                  class="mt-2"
                  style="background: #FFF;"
                  :to="{ name: 'operation-service-requests-show', params: { id: serviceRequest._id } }"
                >
                  <feather-icon
                    icon="ChevronLeftIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span>Return to {{ serviceRequest.stringID }}</span>
                </b-button>
                <b-button
                  v-if="!editFormInOtherStatus && requestForm.status != 'To prepare'"
                  variant="outline-primary"
                  class="mt-2 ml-auto"
                  style="background: #FFF;"
                  @click="editForm"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                  />
                </b-button>
              </div>
            </div>
          </b-col>
          <b-col
            class="content-header-right my-1 d-md-block"
            style="position: relative;"
            md="9"
            lg="9"
            cols="12"
          >
            <!-- Table Container Card -->
            <b-card
              no-body
              class="mb-0"
            >
              <div class="urn-form-wrapper d-form_wrapper__adjust-margin">
                <div class="orn-form-header d-flex justify-content-between mb-75">
                  <div class="d-form-logo_section" />
                  <div class="d-form-no_section text-right">
                    <h5>
                      {{ digitalForm.formNumber }}
                    </h5>
                    <h5>
                      {{ digitalForm.revision }}
                    </h5>
                  </div>
                </div>
                <div class="urn-repository-order_section">
                  <div class="b-form-section-title my-2">
                    <h3>an ling / chai ling form <span>安灵/拆灵表格</span></h3>
                  </div>
                  <div class="mt-2">
                    <b-table-simple
                      class="dark-border-td"
                      bordered
                      responsive
                    >
                      <b-tbody>
                        <b-tr>
                          <b-td>
                            <span>Purchaser Details</span>
                            <br>
                            <span>购买者资料</span>
                          </b-td>
                          <b-td>
                            <b-row>
                              <b-col
                                cols="12"
                                md="12"
                              >
                                <b-form-group
                                  label-cols="12"
                                  label-cols-md="6"
                                  label-cols-lg="3"
                                  label-for="input-default"
                                  label-class="label-width-180"
                                  class="align-items-center"
                                >
                                  <template #label>
                                    <span>Purchaser Name*</span>
                                    <br>
                                    <span>购买者</span>
                                  </template>
                                  <div
                                    v-if="currentCustomerNameType == 'input'"
                                    class="empty-input__text"
                                    :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerName), 'edit-mode': editFormInOtherStatus }"
                                    @click="handleClick('customerName', 'purchaserDetailsRef')"
                                  >
                                    <p
                                      v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerName"
                                      class="placeholder__text"
                                    />
                                    <p v-else>
                                      {{ customerName || '-' }}
                                    </p>
                                  </div>
                                  <div
                                    v-else
                                    :class="{ 'signed': customerNameImage }"
                                    style="border-bottom: 1px solid #000"
                                  >
                                    <b-img
                                      v-if="customerNameImage"
                                      :src="customerNameImage"
                                      class="w-100 signature-image"
                                      alt="sign"
                                    />
                                  </div>
                                </b-form-group>
                              </b-col>
                              <b-col
                                cols="12"
                                md="12"
                              >
                                <b-form-group
                                  label-cols="12"
                                  label-cols-md="6"
                                  label-cols-lg="3"
                                  label-for="input-default"
                                  label-class="label-width-180"
                                  class="align-items-center"
                                >
                                  <template #label>
                                    <span>NRIC*</span>
                                    <br>
                                    <span>身分证号码</span>
                                  </template>
                                  <div
                                    class="empty-input__text"
                                    :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerNRIC), 'edit-mode': editFormInOtherStatus }"
                                    @click="handleClick('customerNRIC', 'purchaserDetailsRef')"
                                  >
                                    <p
                                      v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerNRIC"
                                      class="placeholder__text"
                                    />
                                    <p v-else>
                                      {{ customerNRIC || '-' }}
                                    </p>
                                  </div>
                                </b-form-group>
                              </b-col>
                              <b-col
                                cols="12"
                                md="12"
                              >
                                <b-form-group
                                  label-cols="12"
                                  label-cols-md="6"
                                  label-cols-lg="3"
                                  label-for="input-default"
                                  label-class="label-width-180"
                                  class="align-items-center"
                                >
                                  <template #label>
                                    <span>Contact No.</span>
                                    <br>
                                    <span>联络电话</span>
                                  </template>
                                  <div
                                    class="empty-input__text"
                                    :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerContact), 'edit-mode': editFormInOtherStatus }"
                                    @click="handleClick('customerContact', 'purchaserDetailsRef')"
                                  >
                                    <p
                                      v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerContact"
                                      class="placeholder__text"
                                    />
                                    <p v-else>
                                      {{ customerContact || '-' }}
                                    </p>
                                  </div>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td>
                            <span>Next-of-Kin Details</span>
                            <br>
                            <span>购买者至亲资料</span>
                          </b-td>
                          <b-td>
                            <b-row>
                              <b-col
                                cols="12"
                                md="12"
                              >
                                <b-form-group
                                  label-cols="12"
                                  label-cols-md="6"
                                  label-cols-lg="3"
                                  label-for="input-default"
                                  label-class="label-width-180"
                                  class="align-items-center"
                                >
                                  <template #label>
                                    <span>Next of Kin Name</span>
                                    <br>
                                    <span>至亲姓名</span>
                                  </template>
                                  <div
                                    v-if="currentKinNameType == 'input'"
                                    class="empty-input__text"
                                    :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinName), 'edit-mode': editFormInOtherStatus }"
                                    @click="handleClick('kinName', 'kinDetailsRef')"
                                  >
                                    <p
                                      v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinName"
                                      class="placeholder__text"
                                    />
                                    <p v-else>
                                      {{ kinName || '-' }}
                                    </p>
                                  </div>
                                  <div
                                    v-else
                                    :class="{ 'signed': kinNameImage }"
                                    style="border-bottom: 1px solid #000"
                                  >
                                    <b-img
                                      v-if="kinNameImage"
                                      :src="kinNameImage"
                                      class="w-100 signature-image"
                                      alt="sign"
                                    />
                                  </div>
                                </b-form-group>
                              </b-col>
                              <b-col
                                cols="12"
                                md="12"
                              >
                                <b-form-group
                                  label-cols="12"
                                  label-cols-md="6"
                                  label-cols-lg="3"
                                  label="NRIC* 身分证号码"
                                  label-for="input-default"
                                  label-class="label-width-180"
                                  class="align-items-center"
                                >
                                  <template #label>
                                    <span>NRIC</span>
                                    <br>
                                    <span>身分证号码</span>
                                  </template>
                                  <div
                                    class="empty-input__text"
                                    :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinNRIC), 'edit-mode': editFormInOtherStatus }"
                                    @click="handleClick('kinNRIC', 'kinDetailsRef')"
                                  >
                                    <p
                                      v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinNRIC"
                                      class="placeholder__text"
                                    />
                                    <p v-else>
                                      {{ kinNRIC || '-' }}
                                    </p>
                                  </div>
                                </b-form-group>
                              </b-col>
                              <b-col
                                cols="12"
                                md="12"
                              >
                                <b-form-group
                                  label-cols="12"
                                  label-cols-md="6"
                                  label-cols-lg="3"
                                  label-for="input-default"
                                  label-class="label-width-180"
                                  class="align-items-center"
                                >
                                  <template #label>
                                    <span>Contact No.</span>
                                    <br>
                                    <span>联络电话</span>
                                  </template>
                                  <div
                                    class="empty-input__text"
                                    :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinContact), 'edit-mode': editFormInOtherStatus }"
                                    @click="handleClick('kinContact', 'kinDetailsRef')"
                                  >
                                    <p
                                      v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinContact"
                                      class="placeholder__text"
                                    />
                                    <p v-else>
                                      {{ kinContact || '-' }}
                                    </p>
                                  </div>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td>
                            <span> Niche and Pedestal Details</span>
                            <br>
                            <span>福位或牌位编号</span>
                          </b-td>
                          <b-td>
                            <b-row>
                              <b-col
                                cols="12"
                                md="12"
                              >
                                <b-form-group
                                  label-cols="12"
                                  label-cols-md="6"
                                  label-cols-lg="3"
                                  label-for="input-default"
                                  label-class="label-width-180"
                                  class="align-items-center"
                                >
                                  <template #label>
                                    <span>Niche*</span>
                                    <br>
                                    <span>福位编号</span>
                                  </template>
                                  <div
                                    class="empty-input__text"
                                    :class="{ 'bg-white': (requestForm.status != 'To prepare' || nicheNo), 'edit-mode': editFormInOtherStatus }"
                                    @click="handleClick('nicheNo', 'nicheDetailsRef')"
                                  >
                                    <p
                                      v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !nicheNo"
                                      class="placeholder__text"
                                    />
                                    <p v-else>
                                      {{ nicheNo || '-' }}
                                    </p>
                                  </div>
                                </b-form-group>
                              </b-col>
                              <b-col
                                cols="12"
                                md="12"
                              >
                                <b-form-group
                                  label-cols="12"
                                  label-cols-md="6"
                                  label-cols-lg="3"
                                  label-for="input-default"
                                  label-class="label-width-180"
                                  class="align-items-center"
                                >
                                  <template #label>
                                    <span>Pedestal No.*</span>
                                    <br>
                                    <span>牌位编号</span>
                                  </template>
                                  <div
                                    class="empty-input__text"
                                    :class="{ 'bg-white': (requestForm.status != 'To prepare' || pedestalNo), 'edit-mode': editFormInOtherStatus }"
                                    @click="handleClick('pedestalNo', 'nicheDetailsRef')"
                                  >
                                    <p
                                      v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !pedestalNo"
                                      class="placeholder__text"
                                    />
                                    <p v-else>
                                      {{ pedestalNo || '-' }}
                                    </p>
                                  </div>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td>
                            <span>Particulars of Deceased</span>
                            <br>
                            <span>先人资料</span>
                          </b-td>
                          <b-td>
                            <b-row>
                              <b-col
                                cols="12"
                                md="12"
                              >
                                <b-form-group
                                  label-cols="12"
                                  label-cols-md="6"
                                  label-cols-lg="3"
                                  label-for="input-default"
                                  label-class="label-width-180"
                                  class="align-items-center"
                                >
                                  <template #label>
                                    <span>Name of Deceased*</span>
                                    <br>
                                    <span>先人名讳</span>
                                  </template>
                                  <div
                                    v-if="currentDeceasedNameType == 'input'"
                                    class="empty-input__text"
                                    :class="{ 'bg-white': (requestForm.status != 'To prepare' || deceasedName), 'edit-mode': editFormInOtherStatus }"
                                    @click="handleClick('deceasedName', 'particularDetailsRef')"
                                  >
                                    <p
                                      v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !deceasedName"
                                      class="placeholder__text"
                                    />
                                    <p v-else>
                                      {{ deceasedName || '-' }}
                                    </p>
                                  </div>
                                  <div
                                    v-else
                                    :class="{ 'signed': deceasedNameImage }"
                                    style="border-bottom: 1px solid #000"
                                  >
                                    <b-img
                                      v-if="deceasedNameImage"
                                      :src="deceasedNameImage"
                                      class="w-100 signature-image"
                                      alt="sign"
                                    />
                                  </div>
                                </b-form-group>
                              </b-col>
                              <b-col
                                cols="12"
                                md="12"
                              >
                                <b-form-group
                                  label-cols="12"
                                  label-cols-md="6"
                                  label-cols-lg="3"
                                  label-for="input-default"
                                  label-class="label-width-180"
                                  class="align-items-center"
                                >
                                  <template #label>
                                    <span>Gender*</span>
                                    <br>
                                    <span>性别</span>
                                  </template>
                                  <b-form-checkbox-group
                                    v-model="genderVal"
                                    :options="genderOptions"
                                    name="gender"
                                    class="form-custom-input-checkbox"
                                    style="pointer-events: none;"
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col
                                cols="12"
                                md="12"
                              >
                                <b-form-group
                                  label-cols="12"
                                  label-cols-md="6"
                                  label-cols-lg="3"
                                  label-for="input-default"
                                  label-class="label-width-180"
                                  class="align-items-center"
                                >
                                  <template #label>
                                    <span>Age*</span>
                                    <br>
                                    <span>享年</span>
                                  </template>
                                  <div
                                    class="empty-input__text"
                                    :class="{ 'bg-white': (requestForm.status != 'To prepare' || age), 'edit-mode': editFormInOtherStatus }"
                                    @click="handleClick('age', 'particularDetailsRef')"
                                  >
                                    <p
                                      v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !age"
                                      class="placeholder__text"
                                    />
                                    <p v-else>
                                      {{ age || '-' }}
                                    </p>
                                  </div>
                                </b-form-group>
                              </b-col>
                              <b-col
                                cols="12"
                                md="12"
                              >
                                <b-form-group
                                  label-cols="12"
                                  label-cols-md="6"
                                  label-cols-lg="3"
                                  label-for="input-default"
                                  label-class="label-width-140"
                                  class="align-items-center"
                                >
                                  <template #label>
                                    <span>Departed Date*</span>
                                    <br>
                                    <span>逝世日期</span>
                                  </template>
                                  <div
                                    class="d-form-input-flex"
                                  >
                                    <b-input-group class="input-group-merge">
                                      <div
                                        class="empty-input__text-with__append"
                                        :class="{ 'bg-white': (requestForm.status != 'To prepare' || departedDay), 'edit-mode': editFormInOtherStatus }"
                                        @click="handleClick('departedDay', 'particularDetailsRef')"
                                      >
                                        <p
                                          v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !departedDay"
                                          class="placeholder__text"
                                        />
                                        <p v-else>
                                          {{ departedDay || '-' }}
                                        </p>
                                        <p>
                                          Day 日
                                        </p>
                                      </div>
                                    </b-input-group>
                                    <b-input-group class="input-group-merge">
                                      <div
                                        class="empty-input__text-with__append"
                                        :class="{ 'bg-white': (requestForm.status != 'To prepare' || departedMonth), 'edit-mode': editFormInOtherStatus }"
                                        style="min-width: 118px"
                                        @click="handleClick('departedMonth', 'particularDetailsRef')"
                                      >
                                        <p
                                          v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !departedMonth"
                                          class="placeholder__text"
                                        />
                                        <p v-else>
                                          {{ departedMonth || '-' }}
                                        </p>
                                        <p>
                                          Month 月
                                        </p>
                                      </div>
                                    </b-input-group>
                                    <b-input-group class="input-group-merge">
                                      <div
                                        class="empty-input__text-with__append"
                                        :class="{ 'bg-white': (requestForm.status != 'To prepare' || departedYear), 'edit-mode': editFormInOtherStatus }"
                                        @click="handleClick('departedYear', 'particularDetailsRef')"
                                      >
                                        <p
                                          v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !departedYear"
                                          class="placeholder__text"
                                        />
                                        <p v-else>
                                          {{ departedYear || '-' }}
                                        </p>
                                        <p>
                                          Year 年
                                        </p>
                                      </div>
                                    </b-input-group>
                                  </div>
                                </b-form-group>
                              </b-col>
                              <b-col
                                cols="12"
                                md="12"
                              >
                                <b-form-group
                                  label-cols="12"
                                  label-cols-md="6"
                                  label-cols-lg="3"
                                  label-for="input-default"
                                  label-class="label-width-180"
                                  class="align-items-center"
                                >
                                  <template #label>
                                    <span>Relationship*</span>
                                    <br>
                                    <span>先人与购买者或至亲关系</span>
                                  </template>
                                  <div
                                    class="empty-input__text"
                                    :class="{ 'bg-white': (requestForm.status != 'To prepare' || relationship), 'edit-mode': editFormInOtherStatus }"
                                    @click="handleClick('relationship', 'particularDetailsRef')"
                                  >
                                    <p
                                      v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !relationship"
                                      class="placeholder__text"
                                    />
                                    <p v-else>
                                      {{ relationship || '-' }}
                                    </p>
                                  </div>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td>
                            <span>Service Record</span>
                            <br>
                            <span>服务记录</span>
                          </b-td>
                          <b-td>
                            <b-row>
                              <b-col
                                cols="12"
                                md="12"
                              >
                                <b-form-group
                                  label-cols="12"
                                  label-cols-md="6"
                                  label-cols-lg="3"
                                  label-for="input-default"
                                  class="align-items-center"
                                >
                                  <template #label>
                                    <span>Allocated Lot</span>
                                    <br>
                                    <span>灵位阁与编号</span>
                                  </template>
                                  <div
                                    class="empty-input__text"
                                    :class="{ 'bg-white': (requestForm.status != 'To prepare' || allocatedLot), 'edit-mode': editFormInOtherStatus }"
                                    @click="handleClick('allocatedLot', 'serviceDetailsRef')"
                                  >
                                    <p
                                      v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !allocatedLot"
                                      class="placeholder__text"
                                    />
                                    <p v-else>
                                      {{ allocatedLot || '-' }}
                                    </p>
                                  </div>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <div
                              class="d-form-an-ling-block"
                              :class="{ 'preparing': (requestForm.status == 'To prepare' || editFormInOtherStatus) }"
                            >
                              <h3>An Ling 安灵</h3>
                              <b-row>
                                <b-col
                                  cols="12"
                                  md="12"
                                >
                                  <b-form-group
                                    label-cols="12"
                                    label-cols-md="5"
                                    label-cols-lg="2"
                                    label-for="input-default"
                                    class="align-items-center"
                                  >
                                    <template #label>
                                      <span>Date</span>
                                      <br>
                                      <span>日期</span>
                                    </template>
                                    <div
                                      class="d-form-input-flex"
                                    >
                                      <b-input-group class="input-group-merge">
                                        <div
                                          class="empty-input__text-with__append"
                                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || anlingDay), 'edit-mode': editFormInOtherStatus }"
                                          @click="handleClick('anlingDay', 'serviceDetailsRef')"
                                        >
                                          <p
                                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !anlingDay"
                                            class="placeholder__text"
                                          />
                                          <p v-else>
                                            {{ anlingDay || '-' }}
                                          </p>
                                          <p>
                                            Day 日
                                          </p>
                                        </div>
                                      </b-input-group>
                                      <b-input-group class="input-group-merge">
                                        <div
                                          class="empty-input__text-with__append"
                                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || anlingMonth), 'edit-mode': editFormInOtherStatus }"
                                          @click="handleClick('anlingMonth', 'serviceDetailsRef')"
                                        >
                                          <p
                                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !anlingMonth"
                                            class="placeholder__text"
                                          />
                                          <p v-else>
                                            {{ anlingMonth || '-' }}
                                          </p>
                                          <p>
                                            Month 月
                                          </p>
                                        </div>
                                      </b-input-group>
                                      <b-input-group class="input-group-merge">
                                        <div
                                          class="empty-input__text-with__append"
                                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || anlingYear), 'edit-mode': editFormInOtherStatus }"
                                          @click="handleClick('anlingYear', 'serviceDetailsRef')"
                                        >
                                          <p
                                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !anlingYear"
                                            class="placeholder__text"
                                          />
                                          <p v-else>
                                            {{ anlingYear || '-' }}
                                          </p>
                                          <p>
                                            Year 年
                                          </p>
                                        </div>
                                      </b-input-group>
                                    </div>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row class="mt-2">
                                <b-col
                                  cols="12"
                                  md="12"
                                >
                                  <p>Purchaser / Authorized Representative Signatory
                                    <br>
                                    <span>购买者/受委托领瓮人签名</span>
                                  </p>
                                  <div
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    v-b-modal.purchaser-sign-modal-first
                                    class="form-signature-box"
                                    style="width: 60%"
                                    :class="{ 'signed': isPurchaserSignFirst, 'edit-mode': editFormInOtherStatus }"
                                    :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                                  >
                                    <div class="form-signature-content">
                                      <span
                                        v-if="!isPurchaserSignFirst"
                                        class="form-signature-text"
                                      >
                                        Sign here
                                      </span>
                                      <b-img
                                        v-else
                                        :src="purchaserSignFirst"
                                        alt="authorized-sign"
                                      />
                                    </div>
                                  </div>
                                  <div class="signature-date-block">
                                    <p>Name 姓名: {{ customerName }}</p>
                                    <p v-if="purchaserSignFirstDate">
                                      Date 日期: {{ dateFormatWithTime(purchaserSignFirstDate) }}
                                    </p>
                                    <p v-else>
                                      Date 日期:
                                    </p>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                            <div class="d-form-an-ling-block mt-2">
                              <h3>Chai Ling 拆灵</h3>
                              <b-row>
                                <b-col
                                  cols="12"
                                  md="12"
                                  sm="12"
                                >
                                  <b-form-group
                                    label-cols="12"
                                    label-cols-md="5"
                                    label-cols-lg="2"
                                    label-for="input-default"
                                    class="align-items-center"
                                  >
                                    <template #label>
                                      <span>Date</span>
                                      <br>
                                      <span>日期</span>
                                    </template>
                                    <div
                                      class="d-form-input-flex"
                                    >
                                      <b-input-group class="input-group-merge">
                                        <div
                                          class="empty-input__text-with__append"
                                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || chaiLingDay), 'edit-mode': editFormInOtherStatus }"
                                          @click="handleClick('chaiLingDay', 'serviceDetailsRef')"
                                        >
                                          <p
                                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !chaiLingDay"
                                            class="placeholder__text"
                                          />
                                          <p v-else>
                                            {{ chaiLingDay || '-' }}
                                          </p>
                                          <p>
                                            Day 日
                                          </p>
                                        </div>
                                      </b-input-group>
                                      <b-input-group class="input-group-merge">
                                        <div
                                          class="empty-input__text-with__append"
                                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || chaiLingMonth), 'edit-mode': editFormInOtherStatus }"
                                          @click="handleClick('chaiLingMonth', 'serviceDetailsRef')"
                                        >
                                          <p
                                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !chaiLingMonth"
                                            class="placeholder__text"
                                          />
                                          <p v-else>
                                            {{ chaiLingMonth || '-' }}
                                          </p>
                                          <p>
                                            Month 月
                                          </p>
                                        </div>
                                      </b-input-group>
                                      <b-input-group class="input-group-merge">
                                        <div
                                          class="empty-input__text-with__append"
                                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || chaiLingYear), 'edit-mode': editFormInOtherStatus }"
                                          @click="handleClick('chaiLingYear', 'serviceDetailsRef')"
                                        >
                                          <p
                                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !chaiLingYear"
                                            class="placeholder__text"
                                          />
                                          <p v-else>
                                            {{ chaiLingYear || '-' }}
                                          </p>
                                          <p>
                                            Year 年
                                          </p>
                                        </div>
                                      </b-input-group>
                                    </div>
                                  </b-form-group>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="12"
                                  sm="12"
                                >
                                  <b-form-group
                                    label-cols="12"
                                    label-cols-md="5"
                                    label-cols-lg="2"
                                    label-for="input-default"
                                    class="align-items-center"
                                  >
                                    <template #label>
                                      <span>49 Days</span>
                                      <br>
                                      <span>尾七</span>
                                    </template>
                                    <div
                                      class="d-form-input-flex"
                                    >
                                      <b-input-group class="input-group-merge">
                                        <div
                                          class="empty-input__text-with__append"
                                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || chaiLing49Day), 'edit-mode': editFormInOtherStatus }"
                                          @click="handleClick('chaiLing49Day', 'serviceDetailsRef')"
                                        >
                                          <p
                                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !chaiLing49Day"
                                            class="placeholder__text"
                                          />
                                          <p v-else>
                                            {{ chaiLing49Day || '-' }}
                                          </p>
                                          <p>
                                            Day 日
                                          </p>
                                        </div>
                                      </b-input-group>
                                      <b-input-group class="input-group-merge">
                                        <div
                                          class="empty-input__text-with__append"
                                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || chaiLing49Month), 'edit-mode': editFormInOtherStatus }"
                                          @click="handleClick('chaiLing49Month', 'serviceDetailsRef')"
                                        >
                                          <p
                                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !chaiLing49Month"
                                            class="placeholder__text"
                                          />
                                          <p v-else>
                                            {{ chaiLing49Month || '-' }}
                                          </p>
                                          <p>
                                            Month 月
                                          </p>
                                        </div>
                                      </b-input-group>
                                      <b-input-group class="input-group-merge">
                                        <div
                                          class="empty-input__text-with__append"
                                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || chaiLing49Year), 'edit-mode': editFormInOtherStatus }"
                                          @click="handleClick('chaiLing49Year', 'serviceDetailsRef')"
                                        >
                                          <p
                                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !chaiLing49Year"
                                            class="placeholder__text"
                                          />
                                          <p v-else>
                                            {{ chaiLing49Year || '-' }}
                                          </p>
                                          <p>
                                            Year 年
                                          </p>
                                        </div>
                                      </b-input-group>
                                    </div>
                                  </b-form-group>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="12"
                                  sm="12"
                                >
                                  <b-form-group
                                    label-cols="12"
                                    label-cols-md="5"
                                    label-cols-lg="2"
                                    label-for="input-default"
                                    class="align-items-center"
                                  >
                                    <template #label>
                                      <span>100 Days</span>
                                      <br>
                                      <span>百日</span>
                                    </template>
                                    <div
                                      class="d-form-input-flex"
                                    >
                                      <b-input-group class="input-group-merge">
                                        <b-input-group class="input-group-merge">
                                          <div
                                            class="empty-input__text-with__append"
                                            :class="{ 'bg-white': (requestForm.status != 'To prepare' || chaiLing100Day), 'edit-mode': editFormInOtherStatus }"
                                            @click="handleClick('chaiLing100Day', 'serviceDetailsRef')"
                                          >
                                            <p
                                              v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !chaiLing100Day"
                                              class="placeholder__text"
                                            />
                                            <p v-else>
                                              {{ chaiLing100Day || '-' }}
                                            </p>
                                            <p>
                                              Day 日
                                            </p>
                                          </div>
                                        </b-input-group>
                                      </b-input-group>
                                      <b-input-group class="input-group-merge">
                                        <div
                                          class="empty-input__text-with__append"
                                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || chaiLing100Month), 'edit-mode': editFormInOtherStatus }"
                                          @click="handleClick('chaiLing100Month', 'serviceDetailsRef')"
                                        >
                                          <p
                                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !chaiLing100Month"
                                            class="placeholder__text"
                                          />
                                          <p v-else>
                                            {{ chaiLing100Month || '-' }}
                                          </p>
                                          <p>
                                            Month 月
                                          </p>
                                        </div>
                                      </b-input-group>
                                      <b-input-group class="input-group-merge">
                                        <div
                                          class="empty-input__text-with__append"
                                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || chaiLing100Year), 'edit-mode': editFormInOtherStatus }"
                                          @click="handleClick('chaiLing100Year', 'serviceDetailsRef')"
                                        >
                                          <p
                                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !chaiLing100Year"
                                            class="placeholder__text"
                                          />
                                          <p v-else>
                                            {{ chaiLing100Year || '-' }}
                                          </p>
                                          <p>
                                            Year 年
                                          </p>
                                        </div>
                                      </b-input-group>
                                    </div>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row class="mt-2">
                                <b-col
                                  cols="12"
                                  md="12"
                                >
                                  <p>Purchaser / Authorized Representative Signatory
                                    <br>
                                    <span>购买者/受委托领瓮人签名</span>
                                  </p>
                                  <div
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    v-b-modal.purchaser-sign-modal-second
                                    class="form-signature-box"
                                    style="width: 60%"
                                    :class="{ 'signed': isPurchaserSignSecond, 'edit-mode': editFormInOtherStatus }"
                                    :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                                  >
                                    <div class="form-signature-content">
                                      <span
                                        v-if="!isPurchaserSignSecond"
                                        class="form-signature-text"
                                      >
                                        Sign here
                                      </span>
                                      <b-img
                                        v-else
                                        :src="purchaserSignSecond"
                                        alt="authorized-sign"
                                      />
                                    </div>
                                  </div>
                                  <div class="signature-date-block">
                                    <p>Name 姓名: {{ customerName }}</p>
                                    <p v-if="purchaserSignSecondDate">
                                      Date 日期: {{ dateFormatWithTime(purchaserSignSecondDate) }}
                                    </p>
                                    <p v-else>
                                      Date 日期:
                                    </p>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td>
                            <span>Other Requests</span>
                            <br>
                            <span>其他需求</span>
                          </b-td>
                          <b-td>
                            <b-row>
                              <b-col
                                cols="12"
                                md="12"
                              >
                                <b-form-textarea
                                  id="remarks"
                                  ref="remarks"
                                  v-model="remarks"
                                  rows="3"
                                  readonly
                                  class="form-custom-input"
                                  :class="{ 'value-filled': remarks, 'edit-mode': editFormInOtherStatus }"
                                  placeholder="Enter"
                                  @click="handleClick('remarks', 'othersDetailsRef')"
                                />
                              </b-col>
                            </b-row>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  <div class="d-form-border mt-4">
                    <b-row>
                      <b-col
                        md="6"
                        class="d-form-border-right border-right-none pr-0 padding-right-auto"
                      >
                        <div class="padding-20">
                          <b-form-group
                            label-cols="12"
                            label="Agent Name 代理姓名"
                            label-for="input-default"
                            class="align-items-center"
                          >
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || agentName), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('agentName', 'officeDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !agentName"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ agentName || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                          <b-form-group
                            label-cols="12"
                            label="Agent Contact No. 代理联络电话"
                            label-for="input-default"
                            class="align-items-center"
                          >
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || agentContact), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('agentContact', 'officeDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !agentContact"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ agentContact || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                        </div>
                      </b-col>
                      <b-col
                        md="6"
                        class="pl-0 padding-left-auto"
                      >
                        <div class="padding-20">
                          <p class="d-form-signature-title">
                            Service Staff Signature 服务人员签名:
                          </p>
                          <div
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            v-b-modal.verified-sign-modal
                            class="form-signature-box"
                            :class="{ 'signed': verifiedSign, 'edit-mode': editFormInOtherStatus }"
                            :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                          >
                            <div class="form-signature-content">
                              <span
                                v-if="!verifiedSign"
                                class="form-signature-text"
                              >
                                Sign here
                              </span>
                              <b-img
                                v-else
                                :src="verifiedSignSrc"
                                alt="authorized-sign"
                              />
                            </div>
                          </div>
                          <div class="signature-date-block">
                            <p>Name 姓名: {{ verifiedSignName }}</p>
                            <p v-if="verifiedSignDate">
                              Date 日期: {{ dateFormatWithTime(verifiedSignDate) }}
                            </p>
                            <p v-else>
                              Date 日期:
                            </p>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <div
          v-if="requestForm.status == 'To prepare'"
          class="d-form_action-btn_group wrap-border save-nav"
        >
          <p
            v-if="isMobile()"
            class="margin-left-20 mt-50 mb-50 d-form_action-title"
          >
            <span>Anling/ Chai Ling Form</span>
          </p>
          <b-nav
            :class="{'d-form_action-btn_tablet': isMobile()}"
            class="padding-left-right-20"
          >
            <li
              v-if="!isMobile()"
              class="nav-item"
            >
              <span>Anling/ Chai Ling Form</span>
            </li>
            <li
              class="nav-item"
              :class="{'d-form_action-btn__mob-li': isMobile(), 'ml-auto margin-right-5': !isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="outline-primary"
                @click="submitSaveForm('saveOnly')"
              >
                Save changes
              </b-button>
            </li>
            <li
              class="nav-item d-form_action-second_btn"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                @click="makeFormNotRequired"
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span class="align-middle">Mark as Not Required</span>
              </b-button>
            </li>
            <li
              class="nav-item d-form_action-third_btn"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                @click="submitSaveForm('readyToSign')"
              >
                <feather-icon
                  icon="FeatherIcon"
                  class="mr-50"
                />
                <span class="align-middle">Mark as Ready to Sign</span>
              </b-button>
            </li>
          </b-nav>
        </div>

        <div
          v-if="editFormInOtherStatus"
          class="d-form_action-btn_group wrap-border save-nav"
        >
          <p
            v-if="isMobile()"
            class="margin-left-20 mt-50 mb-50 d-form_action-title"
          >
            <span>Editing Anling/ Chai Ling Form</span>
          </p>
          <b-nav
            class="padding-left-right-20"
            :class="{'d-form_action-btn_tablet': isMobile()}"
          >
            <li
              v-if="!isMobile()"
              class="nav-item"
            >
              <span>Editing Anling/ Chai Ling Form</span>
            </li>
            <li
              :class="{'ml-auto margin-right-5': !isMobile(), 'mob__cancel-btn': isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="outline-primary"
                class="action-cancel_btn"
                @click="editFormInOtherStatus = false; makeFormValues()"
              >
                Cancel Editing
              </b-button>
            </li>
            <li
              class="nav-item"
              :class="{'d-form_action-editing__btn': isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                class="action-done_btn"
                @click="submitSaveFormConfirmation()"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                <span class="align-middle">Done</span>
              </b-button>
            </li>
          </b-nav>
        </div>
      </b-form>
    </validation-observer>
    <!-- authorized signature modal -->
    <b-modal
      id="purchaser-sign-modal-first"
      ref="purchaser-sign-modal-first"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Authorized Signature"
      @ok="closePurchaserSignFirstModal"
      @cancel="closePurchaserSignFirstModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Purchaser / Authorized Representative Signatory & Date</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearPurchaserSignFirst()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePurchaserSignFirst()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="purchaser-sign-modal-second"
      ref="purchaser-sign-modal-second"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Authorized Signature"
      @ok="closePurchaserSignSecondModal"
      @cancel="closePurchaserSignSecondModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Purchaser / Authorized Representative Signatory & Date</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearPurchaserSignSecond()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePurchaserSignSecond()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ authorized signature modal -->
    <!-- verified by signature modal -->
    <b-modal
      id="verified-sign-modal"
      ref="verified-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Verified By"
      @ok="closeVerifiedSignModal"
      @cancel="closeVerifiedSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Service Staff</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearVerifiedSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveVerifiedSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ verified by signature modal -->
    <!-- customer name modal -->
    <b-modal
      id="customer-name-modal"
      ref="customer-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeCustomerNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="customerSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="customerNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearCustomerName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveCustomerName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- customer name -->
    <!-- kin name modal -->
    <b-modal
      id="kin-name-modal"
      ref="kin-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeKinNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="kinSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="kinNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearKinName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveKinName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="deceased-name-modal"
      ref="deceased-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeKinNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="kinSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="kinNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearKinName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveKinName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- kin name -->

    <!-- submit signed form -->
    <b-modal
      id="submit-form-modal"
      ref="submit-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this signed form?"
      @ok="submitSignedForm"
      @cancel="closeSubmitFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="+65 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="resend-form-modal"
      ref="resend-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Resend this form?"
      @ok="resendSignedForm"
      @cancel="closeResendFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="+65 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to Form
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="edit-form-modal"
      ref="edit-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this edited form?"
      @ok="submitSaveForm('edited', true)"
      @cancel="cancelEditing"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="+65 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BImg, BButton, BNav, BForm, BTab, BTabs,
  BTableSimple, BTbody, BTr, BTd, BFormRadioGroup, BFormTextarea, BFormCheckboxGroup, BInputGroup,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContentCustom from '../../ToastificationContentCustom.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BButton,
    BNav,
    BForm,
    BTab,
    BTabs,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BFormRadioGroup,
    BFormTextarea,
    BInputGroup,
    BFormCheckboxGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentCustom,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      isPurchaserSignFirst: false,
      isPurchaserSignSecond: false,
      purchaserSignFirst: '',
      purchaserSignFirstDate: '',
      purchaserSignSecond: '',
      purchaserSignSecondDate: '',
      verifiedSign: false,
      verifiedSignSrc: '',
      verifiedSignDate: '',
      verifiedSignName: '',
      customerNameImageError: false,
      kinNameImageError: false,
      deceasedNameImageError: false,
      kinCollapse: false,
      purchaserCollapse: true,
      nicheCollapse: false,
      officeCollapse: false,
      particularCollapse: false,
      serviceCollapse: false,
      otherDetailsCollapse: false,
      editFormInOtherStatus: false,
      tabActive: 'email',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      deceasedNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      currentCustomerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerNRIC: '',
      customerContact: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinNRIC: '',
      kinContact: '',
      nicheNo: '',
      pedestalNo: '',
      agentName: '',
      agentContact: '',
      currentDeceasedNameType: 'input',
      deceasedName: '',
      deceasedNameImage: '',
      gender: '',
      genderVal: [],
      age: '',
      departedDay: '',
      departedMonth: '',
      departedYear: '',
      relationship: '',
      allocatedLot: '',
      anlingDay: '',
      anlingMonth: '',
      anlingYear: '',
      chaiLingDay: '',
      chaiLingMonth: '',
      chaiLingYear: '',
      chaiLing49Day: '',
      chaiLing49Month: '',
      chaiLing49Year: '',
      chaiLing100Day: '',
      chaiLing100Month: '',
      chaiLing100Year: '',
      remarks: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      existingVerifiedSignSrcObject: {},
      existingPurchaserSignFirstObject: {},
      existingPurchaserSignSecondObject: {},
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Type your message here',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Type your message here',
      },
      genderOptions: [
        { text: 'Male 男', value: 'Male 男' },
        { text: 'Female 女', value: 'Female 女' },
      ],
      required,
    }
  },
  created() {
    this.makeFormValues()
    this.customerMobileVal = this.requestForm.customerContact
    this.customerEmailVal = this.requestForm.customerEmail
  },
  methods: {
    makeFormValues() {
      this.nicheNo = this.serviceRequest.serviceForm.deceasedNiche
      this.pedestalNo = this.serviceRequest.serviceForm.deceasedPedestal
      this.agentName = this.serviceRequest.serviceForm.agentName
      this.agentContact = this.serviceRequest.serviceForm.agentContact
      const gender = this.serviceRequest.serviceForm.deceasedGender
      let genderName = ''
      if (gender === 'male') {
        genderName = 'Male 男'
      } else if (gender === 'female') {
        genderName = 'Female 女'
      }
      this.gender = genderName
      this.genderVal = [genderName]
      this.age = this.serviceRequest.serviceForm.deceasedAge
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.currentCustomerNameType = 'canvas'
        } else {
          this.currentCustomerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.customerNRIC = this.serviceRequest.formValues.find(o => o.key === 'customerNRIC')?.value
      this.customerContact = this.serviceRequest.formValues.find(o => o.key === 'customerContact')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.currentKinNameType = 'canvas'
        } else {
          this.currentKinNameType = 'input'
          this.kinName = kinNameObject.value
        }
      }
      this.kinNRIC = this.serviceRequest.formValues.find(o => o.key === 'kinNRIC')?.value
      this.kinContact = this.serviceRequest.formValues.find(o => o.key === 'kinContact')?.value
      this.deceasedName = this.serviceRequest.serviceForm.deceasedName
      if (this.requestForm.values.length) {
        // const customerNameObject = this.requestForm.values.find(o => o.key === 'customerName')
        const deceasedNameObject = this.requestForm.values.find(o => o.key === 'deceasedName')
        if (deceasedNameObject) {
          if (deceasedNameObject.value.startsWith('data:image/')) {
            this.deceasedNameImage = deceasedNameObject.value
            this.currentDeceasedNameType = 'canvas'
          } else {
            this.currentDeceasedNameType = 'input'
            this.deceasedName = this.serviceRequest.serviceForm.deceasedName
          }
        }
        const departedDayObject = this.requestForm.values.find(o => o.key === 'departedDay')
        if (departedDayObject) {
          this.departedDay = departedDayObject.value
        }
        const departedMonthObject = this.requestForm.values.find(o => o.key === 'departedMonth')
        if (departedMonthObject) {
          this.departedMonth = departedMonthObject.value
        }
        const departedYearObject = this.requestForm.values.find(o => o.key === 'departedYear')
        if (departedYearObject) {
          this.departedYear = departedYearObject.value
        }
        const relationshipObject = this.requestForm.values.find(o => o.key === 'relationship')
        if (relationshipObject) {
          this.relationship = relationshipObject.value
        }
        const allocatedLotObject = this.requestForm.values.find(o => o.key === 'allocatedLot')
        if (allocatedLotObject) {
          this.allocatedLot = allocatedLotObject.value
        }
        const anlingDayObject = this.requestForm.values.find(o => o.key === 'anlingDay')
        if (anlingDayObject) {
          this.anlingDay = anlingDayObject.value
        }
        const anlingMonthObject = this.requestForm.values.find(o => o.key === 'anlingMonth')
        if (anlingMonthObject) {
          this.anlingMonth = anlingMonthObject.value
        }
        const anlingYearObject = this.requestForm.values.find(o => o.key === 'anlingYear')
        if (anlingYearObject) {
          this.anlingYear = anlingYearObject.value
        }
        const chaiLingDayObject = this.requestForm.values.find(o => o.key === 'chaiLingDay')
        if (chaiLingDayObject) {
          this.chaiLingDay = chaiLingDayObject.value
        }
        const chaiLingMonthObject = this.requestForm.values.find(o => o.key === 'chaiLingMonth')
        if (chaiLingMonthObject) {
          this.chaiLingMonth = chaiLingMonthObject.value
        }
        const chaiLingYearObject = this.requestForm.values.find(o => o.key === 'chaiLingYear')
        if (chaiLingYearObject) {
          this.chaiLingYear = chaiLingYearObject.value
        }
        const chaiLing49DayObject = this.requestForm.values.find(o => o.key === 'chaiLing49Day')
        if (chaiLing49DayObject) {
          this.chaiLing49Day = chaiLing49DayObject.value
        }
        const chaiLing49MonthObject = this.requestForm.values.find(o => o.key === 'chaiLing49Month')
        if (chaiLing49MonthObject) {
          this.chaiLing49Month = chaiLing49MonthObject.value
        }
        const chaiLing49YearObject = this.requestForm.values.find(o => o.key === 'chaiLing49Year')
        if (chaiLing49YearObject) {
          this.chaiLing49Year = chaiLing49YearObject.value
        }
        const chaiLing100DayObject = this.requestForm.values.find(o => o.key === 'chaiLing100Day')
        if (chaiLing100DayObject) {
          this.chaiLing100Day = chaiLing100DayObject.value
        }
        const chaiLing100MonthObject = this.requestForm.values.find(o => o.key === 'chaiLing100Month')
        if (chaiLing100MonthObject) {
          this.chaiLing100Month = chaiLing100MonthObject.value
        }
        const chaiLing100YearObject = this.requestForm.values.find(o => o.key === 'chaiLing100Year')
        if (chaiLing100YearObject) {
          this.chaiLing100Year = chaiLing100YearObject.value
        }
        const remarksObject = this.requestForm.values.find(o => o.key === 'remarks')
        if (remarksObject) {
          this.remarks = remarksObject.value
        }
        const purchaserSignFirstObject = this.requestForm.values.find(o => o.key === 'purchaserSignFirst')
        if (purchaserSignFirstObject) {
          this.existingPurchaserSignFirstObject = purchaserSignFirstObject
          this.purchaserSignFirst = purchaserSignFirstObject.value
          this.isPurchaserSignFirst = true
          this.purchaserSignFirstDate = purchaserSignFirstObject.date
        }
        const purchaserSignSecondObject = this.requestForm.values.find(o => o.key === 'purchaserSignSecond')
        if (purchaserSignSecondObject) {
          this.existingPurchaserSignSecondObject = purchaserSignSecondObject
          this.purchaserSignSecond = purchaserSignSecondObject.value
          this.isPurchaserSignSecond = true
          this.purchaserSignSecondDate = purchaserSignSecondObject.date
        }
        const verifiedSignSrcObject = this.requestForm.values.find(o => o.key === 'verifiedSignSrc')
        if (verifiedSignSrcObject) {
          this.existingVerifiedSignSrcObject = verifiedSignSrcObject
          this.verifiedSignSrc = verifiedSignSrcObject.value
          this.verifiedSign = true
          this.verifiedSignDate = verifiedSignSrcObject.date
          this.verifiedSignName = verifiedSignSrcObject.name
        }
      }
    },
    startSignProcess() {
      const finalStatus = 'Signing'
      const formData = new FormData()
      formData.append('status', finalStatus)
      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editForm() {
      if (this.requestForm.status === 'Not required') {
        const finalStatus = 'To prepare'
        const formData = new FormData()
        formData.append('status', finalStatus)
        this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              await this.$emit('update:requestForm', response.data.data)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.editFormInOtherStatus = true
      }
    },
    makeFormNotRequired() {
      this.$swal({
        title: 'Are you sure you want to ‘Mark as Not Required’ for this form?',
        html: 'The form status will change to ‘Not Required’, all information you have keyed in will be cleared.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: "Yes, I'm sure",
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('status', 'Not required')
            this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(async response => {
                if (response.data.data) {
                  await this.$emit('update:requestForm', response.data.data)
                  this.makeFormValues()
                  this.$swal({
                    title: 'Form Has Been Marked as Not Required',
                    // html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message || '',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    closePurchaserSignFirstModal() {
      this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal-first', '')
    },
    clearPurchaserSignFirst() {
      this.$refs.signaturePad.clearSignature()
    },
    savePurchaserSignFirst() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal-first', '')
        const purchaserSignFirstObj = {
          key: 'purchaserSignFirst',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          this.purchaserSignFirst = data
          this.isPurchaserSignFirst = true
          purchaserSignFirstObj.date = new Date()
          this.existingPurchaserSignFirstObject = purchaserSignFirstObj
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.verifiedSign) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(purchaserSignFirstObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    closePurchaserSignSecondModal() {
      this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal-second', '')
    },
    clearPurchaserSignSecond() {
      this.$refs.signaturePad.clearSignature()
    },
    savePurchaserSignSecond() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal-second', '')
        const purchaserSignSecondObj = {
          key: 'purchaserSignSecond',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          this.purchaserSignSecond = data
          this.isPurchaserSignSecond = true
          purchaserSignSecondObj.date = new Date()
          this.existingPurchaserSignSecondObject = purchaserSignSecondObj
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.verifiedSign) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(purchaserSignSecondObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    closeVerifiedSignModal() {
      this.$root.$emit('bv::hide::modal', 'verified-sign-modal', '')
    },
    clearVerifiedSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveVerifiedSign() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'verified-sign-modal', '')
        const verifiedSignObj = {
          key: 'verifiedSignSrc',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          this.verifiedSignSrc = data
          this.verifiedSign = true
          verifiedSignObj.date = new Date()
          verifiedSignObj.name = this.user.name
          verifiedSignObj.admin = this.user._id
          this.existingVerifiedSignSrcObject = verifiedSignObj
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.isPurchaserSignFirst || this.isPurchaserSignSecond) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(verifiedSignObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    closeCustomerNameModal() {
      this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
      if (!this.customerNameImage) {
        this.currentCustomerNameType = 'input'
      }
    },
    closeKinNameModal() {
      this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
      if (!this.kinNameImage) {
        this.currentKinNameType = 'input'
      }
    },
    closeDeceasedNameModal() {
      this.$root.$emit('bv::hide::modal', 'deceased-name-modal', '')
      if (!this.deceasedNameImage) {
        this.currentDeceasedNameType = 'input'
      }
    },
    submitSignedForm(option = null) {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (option === 'edited') {
        formData.append('edited', 'yes')
      }
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.requestForm.formID.name)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)

      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/generate-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
            this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')

            this.makeFormValues()
            this.$toast({
              component: ToastificationContentCustom,
              props: {
                title: 'PDF is ready!',
                text: response.data.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resendSignedFormModal() {
      if (this.requestForm.pdfs.length) {
        this.$root.$emit('bv::show::modal', 'resend-form-modal', '')
      } else {
        this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
      }
    },
    resendSignedForm() {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.requestForm.formID.name)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)

      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/resend-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.$toast({
            component: ToastificationContentCustom,
            props: {
              title: 'PDF is ready!',
              text: response.data.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeSubmitFormModal() {
      this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')
    },
    closeResendFormModal() {
      this.$root.$emit('bv::hide::modal', 'resend-form-modal', '')
    },
    clearCustomerName() {
      this.customerNameImage = ''
      this.$refs.customerSignaturePad.clearSignature()
    },
    clearKinName() {
      this.kinNameImage = ''
      this.$refs.kinSignaturePad.clearSignature()
    },
    clearDeceasedName() {
      this.deceasedNameImage = ''
      this.$refs.deceasedSignaturePad.clearSignature()
    },
    saveCustomerName() {
      const { data } = this.$refs.customerSignaturePad.saveSignature()
      if (data) {
        this.customerNameImage = data
        this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
        this.currentCustomerNameType = 'canvas'
      }
    },
    saveKinName() {
      const { data } = this.$refs.kinSignaturePad.saveSignature()
      if (data) {
        this.kinNameImage = data
        this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
        this.currentKinNameType = 'canvas'
      }
    },
    saveDeceasedName() {
      const { data } = this.$refs.deceasedSignaturePad.saveSignature()
      if (data) {
        this.deceasedNameImage = data
        this.$root.$emit('bv::hide::modal', 'deceased-name-modal', '')
        this.currentDeceasedNameType = 'canvas'
      }
    },
    async handleClick(field, collapse) {
      if (this.requestForm.status === 'To prepare' || this.editFormInOtherStatus) {
        await this.$refs[collapse].updateVisible(true)
        this.$refs[field].focus()
      }
    },
    changeCustomerNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.customerNameImage = ''
              this.currentCustomerNameType = type
            }
          })
      } else {
        this.currentCustomerNameType = type
        this.$root.$emit('bv::show::modal', 'customer-name-modal', '')
      }
    },
    changeKinNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.kinNameImage = ''
              this.currentKinNameType = type
            }
          })
      } else {
        this.currentKinNameType = type
        this.$root.$emit('bv::show::modal', 'kin-name-modal', '')
      }
    },
    changeDeceasedNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.deceasedNameImage = ''
              this.currentDeceasedNameType = type
            }
          })
      } else {
        this.currentDeceasedNameType = type
        this.$root.$emit('bv::show::modal', 'deceased-name-modal', '')
      }
    },
    submitSaveFormConfirmation() {
      if (this.requestForm.status === 'Signed and Sent') {
        this.$root.$emit('bv::show::modal', 'edit-form-modal', '')
      } else {
        this.submitSaveForm()
      }
    },
    cancelEditing() {
      this.$root.$emit('bv::hide::modal', 'edit-form-modal', '')
      // this.editFormInOtherStatus = false
      // this.makeFormValues()
    },
    submitSaveForm(option = null, submit = false) {
      this.$refs.serviceRequestFormSave.validate().then(success => {
        let hasCustomerImage = true
        if (this.currentCustomerNameType === 'canvas' && !this.customerNameImage) {
          hasCustomerImage = false
          this.customerNameImageError = true
        }
        // let hasKinImage = true
        // if (this.currentKinNameType === 'canvas' && !this.kinNameImage) {
        //   hasKinImage = false
        //   this.kinNameImageError = true
        // }
        if ((option && option === 'saveOnly') || (success && hasCustomerImage)) {
          const formData = new FormData()
          if (option && option === 'readyToSign') {
            formData.append('readyToSign', true)
          }
          const valueArray = []
          let srValueArray = this.serviceRequest.formValues
          const sfValueObject = {}
          const srValueObject = {}
          const customerNameObj = {
            key: 'customerName',
          }
          if (this.currentCustomerNameType === 'input') {
            customerNameObj.value = this.customerName
          } else {
            customerNameObj.value = this.customerNameImage
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerNameObj.key).concat(customerNameObj)

          const customerNRICObj = {
            key: 'customerNRIC',
            value: this.customerNRIC,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerNRICObj.key).concat(customerNRICObj)

          const customerContactObj = {
            key: 'customerContact',
            value: this.customerContact,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerContactObj.key).concat(customerContactObj)

          const kinNameObj = {
            key: 'kinName',
          }
          if (this.currentKinNameType === 'input') {
            kinNameObj.value = this.kinName
          } else {
            kinNameObj.value = this.kinNameImage
          }
          srValueArray = srValueArray.filter(obj => obj.key !== kinNameObj.key).concat(kinNameObj)

          const kinNRICObj = {
            key: 'kinNRIC',
            value: this.kinNRIC,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== kinNRICObj.key).concat(kinNRICObj)

          const kinContactObj = {
            key: 'kinContact',
            value: this.kinContact,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== kinContactObj.key).concat(kinContactObj)

          // const nicheNoObj = {
          //   key: 'nicheNo',
          //   value: this.nicheNo,
          // }
          // valueArray.push(nicheNoObj)

          // const pedestalNoObj = {
          //   key: 'pedestalNo',
          //   value: this.pedestalNo,
          // }
          // valueArray.push(pedestalNoObj)

          // const agentNameObj = {
          //   key: 'agentName',
          //   value: this.agentName,
          // }
          // valueArray.push(agentNameObj)

          // const agentContactObj = {
          //   key: 'agentContact',
          //   value: this.agentContact,
          // }
          // valueArray.push(agentContactObj)

          const deceasedNameObj = {
            key: 'deceasedName',
          }
          if (this.currentDeceasedNameType === 'input') {
            deceasedNameObj.value = this.deceasedName
          } else {
            deceasedNameObj.value = this.deceasedNameImage
          }
          valueArray.push(deceasedNameObj)

          // const genderObj = {
          //   key: 'gender',
          //   value: this.gender,
          // }
          // valueArray.push(genderObj)

          // const ageObj = {
          //   key: 'age',
          //   value: this.age,
          // }
          // valueArray.push(ageObj)

          const departedDayObj = {
            key: 'departedDay',
            value: this.departedDay,
          }
          valueArray.push(departedDayObj)

          const departedMonthObj = {
            key: 'departedMonth',
            value: this.departedMonth,
          }
          valueArray.push(departedMonthObj)

          const departedYearObj = {
            key: 'departedYear',
            value: this.departedYear,
          }
          valueArray.push(departedYearObj)

          const relationshipObj = {
            key: 'relationship',
            value: this.relationship,
          }
          valueArray.push(relationshipObj)

          const allocatedLotObj = {
            key: 'allocatedLot',
            value: this.allocatedLot,
          }
          valueArray.push(allocatedLotObj)

          const anlingDayObj = {
            key: 'anlingDay',
            value: this.anlingDay,
          }
          valueArray.push(anlingDayObj)

          const anlingMonthObj = {
            key: 'anlingMonth',
            value: this.anlingMonth,
          }
          valueArray.push(anlingMonthObj)

          const anlingYearObj = {
            key: 'anlingYear',
            value: this.anlingYear,
          }
          valueArray.push(anlingYearObj)

          const chaiLingDayObj = {
            key: 'chaiLingDay',
            value: this.chaiLingDay,
          }
          valueArray.push(chaiLingDayObj)

          const chaiLingMonthObj = {
            key: 'chaiLingMonth',
            value: this.chaiLingMonth,
          }
          valueArray.push(chaiLingMonthObj)

          const chaiLingYearObj = {
            key: 'chaiLingYear',
            value: this.chaiLingYear,
          }
          valueArray.push(chaiLingYearObj)

          const chaiLing49DayObj = {
            key: 'chaiLing49Day',
            value: this.chaiLing49Day,
          }
          valueArray.push(chaiLing49DayObj)

          const chaiLing49MonthObj = {
            key: 'chaiLing49Month',
            value: this.chaiLing49Month,
          }
          valueArray.push(chaiLing49MonthObj)

          const chaiLing49YearObj = {
            key: 'chaiLing49Year',
            value: this.chaiLing49Year,
          }
          valueArray.push(chaiLing49YearObj)

          const chaiLing100DayObj = {
            key: 'chaiLing100Day',
            value: this.chaiLing100Day,
          }
          valueArray.push(chaiLing100DayObj)

          const chaiLing100MonthObj = {
            key: 'chaiLing100Month',
            value: this.chaiLing100Month,
          }
          valueArray.push(chaiLing100MonthObj)

          const chaiLing100YearObj = {
            key: 'chaiLing100Year',
            value: this.chaiLing100Year,
          }
          valueArray.push(chaiLing100YearObj)

          const pcllObj = {
            key: 'pcll',
            value: this.pcll,
          }
          valueArray.push(pcllObj)

          const remarksObj = {
            key: 'remarks',
            value: this.remarks,
          }
          valueArray.push(remarksObj)

          let verifiedSignVal = 0
          let purchaserSignFirstVal = 0
          let purchaserSignSecondVal = 0

          if (this.existingVerifiedSignSrcObject.key) {
            verifiedSignVal = 1
            valueArray.push(this.existingVerifiedSignSrcObject)
          }
          if (this.existingPurchaserSignFirstObject.key) {
            purchaserSignFirstVal = 1
            valueArray.push(this.existingPurchaserSignFirstObject)
          }
          if (this.existingPurchaserSignSecondObject.key) {
            purchaserSignSecondVal = 1
            valueArray.push(this.existingPurchaserSignSecondObject)
          }

          let allSigned = false
          if (verifiedSignVal && (purchaserSignFirstVal || purchaserSignSecondVal)) {
            allSigned = true
          }

          // let valueChanged = false
          // if (this.requestForm.status === 'Signed and Sent') {
          //   const difference = valueArray.filter(({ value: id1 }) => !this.requestForm.values.some(({ value: id2 }) => id2 === id1))
          //   if (difference.length) {
          //     const otherValueChanged = difference.find(o => !['installationDate', 'installationTime'].includes(o.key))
          //     if (otherValueChanged) {
          //       valueChanged = true
          //     }
          //   }
          // }

          formData.append('values', JSON.stringify(valueArray))
          formData.append('srValues', JSON.stringify(srValueArray))
          formData.append('sfValues', JSON.stringify(sfValueObject))
          formData.append('srValueObject', JSON.stringify(srValueObject))
          formData.append('allSigned', allSigned)
          formData.append('currentStatus', this.requestForm.status)
          formData.append('formName', this.requestForm.formID.name)
          formData.append('serviceRequest', this.serviceRequest._id)
          formData.append('serviceForm', this.serviceRequest.serviceForm._id)
          formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                await this.$emit('update:serviceRequest', response.data.sr)
                this.makeFormValues()
                this.editFormInOtherStatus = false
                if (option && option === 'edited' && response.data.data.status === 'Signed and Sent' && submit) {
                  this.submitSignedForm('edited')
                } else if (response.data.data.status === 'Signing' && allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Form Data Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          const items = document.getElementsByClassName('is-invalid')
          if (items && items.length) {
            // window.scrollTo({
            //   top: items[0].offsetHeight + 100,
            //   behavior: 'smooth',
            // })
            items[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }
        }
      })
    },
  },
}
</script>
