import { render, staticRenderFns } from "./UrnStorageRequest.vue?vue&type=template&id=44fc8c67&"
import script from "./UrnStorageRequest.vue?vue&type=script&lang=js&"
export * from "./UrnStorageRequest.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports