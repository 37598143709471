<template>
  <div>
    <validation-observer
      ref="serviceRequestFormSave"
    >
      <b-form @submit.prevent="submitSaveForm">
        <b-row
          class="content-header v-sticky-sidebar-container service_request_task collapse__form-wrapper"
          :class="{ 'white-background': !editFormInOtherStatus, 'green-background': editFormInOtherStatus }"
          style="border-radius: 6px;"
        >
          <!-- Content Left -->
          <b-col
            class="content-header-left my-1 top-info-right"
            cols="12"
            md="3"
          >
            <div
              class="user_block ac_type"
              style="float: none;"
            >
              <div class="user_avatar" />
              <div class="user_info">
                <h6>Status</h6>
                <h5 class="">
                  {{ requestForm.status }}
                </h5>
              </div>
            </div>

            <b-card
              v-if="editFormInOtherStatus"
              class="mt-2 service-forms-card success-card"
            >
              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class=""
                    size="20"
                    style="color: #fff;"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  <span class="step-class">you're now</span>
                  Editing this form
                </h3>
              </template>
              <b-card-text
                class="signing-stage__title"
              >
                <span>After submitting the signed form, the system will generate a signed PDF copy and email to customer.</span>
              </b-card-text>
            </b-card>

            <app-collapse
              v-if="requestForm.status == 'To prepare' || editFormInOtherStatus"
              accordion
              class="mt-2 service-form-collapse"
            >
              <app-collapse-item
                ref="genderDetailsRef"
                title="Male/Female"
                class="custom-collapse-icon"
                :is-visible="genderCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Male/Female</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="gender"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="gender"
                        vid="gender"
                        rules="required"
                      >
                        <b-form-radio-group
                          id="gender"
                          ref="gender"
                          v-model="gender"
                          name="gender"
                          class="form-custom-input-checkbox"
                          :options="genderOptions"
                          @input="genderVal = [gender]"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="deceasedDetailsRef"
                title="Deceased Details"
                class="custom-collapse-icon"
                :is-visible="deceasedCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Deceased Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label">Deceased 往生者</span>
                    </div>
                    <b-form-group
                      v-if="currentDeceasedNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="deceasedName"
                        rules=""
                      >
                        <b-form-input
                          id="input-default"
                          ref="deceasedName"
                          v-model="deceasedName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                          border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': deceasedNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeDeceasedNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="deceasedNameImage"
                        v-b-modal.deceased-name-modal
                        :src="deceasedNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentDeceasedNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeDeceasedNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeDeceasedNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label">Native Place 籍贯</span>
                    </div>
                    <b-form-group
                      v-if="currentDeceasedNativeType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="deceasedNative"
                        rules=""
                      >
                        <b-form-input
                          id="input-default"
                          ref="deceasedNative"
                          v-model="deceasedNative"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                          border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': deceasedNativeImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeDeceasedNativeInput('input')"
                        />
                      </div>
                      <img
                        v-if="deceasedNativeImage"
                        v-b-modal.deceased-native-modal
                        :src="deceasedNativeImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentDeceasedNativeType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeDeceasedNativeInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeDeceasedNativeInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <div class="p-1 mt-50">
                    <span class="text-bold-black">Birth date/time 生日/时</span>
                  </div>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Birth Date"
                      vid="birthDate"
                      rules="date"
                    >
                      <b-form-group
                        label="Gregorian 阳历"
                        label-class="field-label"
                        label-for="birthDate"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <!-- <b-input-group>
                          <flat-pickr
                            id="birthDate"
                            ref="birthDate"
                            v-model="birthDate"
                            class="form-control flat-pickr-group"
                            placeholder="Birth Date"
                            :config="flatPickrConfig"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group> -->
                        <cleave
                          id="birthDate"
                          ref="birthDate"
                          v-model="birthDate"
                          class="form-control flat-pickr-group"
                          :raw="false"
                          :options="cleaveOptions.delimiter"
                          placeholder="Birth Date"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Lunar 农历"
                      label-for="birthLunarYear"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="birthLunarYear"
                        vid="birthLunarYear"
                        rules=""
                      >
                        <b-form-input
                          id="birthLunarYear"
                          ref="birthLunarYear"
                          v-model="birthLunarYear"
                          placeholder="Year 年"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="birthLunarMonth"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="birthLunarMonth"
                        vid="birthLunarMonth"
                        rules=""
                      >
                        <b-form-input
                          id="birthLunarMonth"
                          ref="birthLunarMonth"
                          v-model="birthLunarMonth"
                          placeholder="Month 月"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="birthLunarDay"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="birthLunarDay"
                        vid="birthLunarDay"
                        rules=""
                      >
                        <b-form-input
                          id="birthLunarDay"
                          ref="birthLunarDay"
                          v-model="birthLunarDay"
                          placeholder="Day 日"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="birthLunarTime"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="birthLunarTime"
                        vid="birthLunarTime"
                        rules=""
                      >
                        <b-form-input
                          id="birthLunarTime"
                          ref="birthLunarTime"
                          v-model="birthLunarTime"
                          placeholder="Time 时"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <div class="p-1 mt-50">
                    <span class="text-bold-black">Death date/time 歿日/时</span>
                  </div>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Death Date"
                      vid="deathDate"
                      rules="date:MM/DD/YYYY"
                    >
                      <b-form-group
                        label="Gregorian 阳历"
                        label-class="field-label"
                        label-for="deathDate"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <!-- <b-input-group>
                          <flat-pickr
                            id="deathDate"
                            ref="deathDate"
                            v-model="deathDate"
                            class="form-control flat-pickr-group"
                            placeholder="Death Date"
                            :config="flatPickrConfig"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group> -->
                        <cleave
                          id="deathDate"
                          ref="deathDate"
                          v-model="deathDate"
                          class="form-control flat-pickr-group"
                          :raw="false"
                          :options="cleaveOptions.delimiter"
                          placeholder="Death Date"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Lunar 农历"
                      label-for="deathLunarYear"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="deathLunarYear"
                        vid="deathLunarYear"
                        rules=""
                      >
                        <b-form-input
                          id="deathLunarYear"
                          ref="deathLunarYear"
                          v-model="deathLunarYear"
                          placeholder="Year 年"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="deathLunarMonth"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="deathLunarMonth"
                        vid="deathLunarMonth"
                        rules=""
                      >
                        <b-form-input
                          id="deathLunarMonth"
                          ref="deathLunarMonth"
                          v-model="deathLunarMonth"
                          placeholder="Month 月"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="deathLunarDay"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="deathLunarDay"
                        vid="deathLunarDay"
                        rules=""
                      >
                        <b-form-input
                          id="deathLunarDay"
                          ref="deathLunarDay"
                          v-model="deathLunarDay"
                          placeholder="Day 日"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="deathLunarTime"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="deathLunarTime"
                        vid="deathLunarTime"
                        rules=""
                      >
                        <b-form-input
                          id="deathLunarTime"
                          ref="deathLunarTime"
                          v-model="deathLunarTime"
                          placeholder="Time 时"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="purchaserDetailsRef"
                title="Purchaser Details"
                class="custom-collapse-icon"
                :is-visible="purchaserCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Purchaser Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label label-required-star">Purchaser 购买者</span>
                    </div>
                    <b-form-group
                      v-if="currentCustomerNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Purchaser"
                        rules="required"
                      >
                        <b-form-input
                          id="input-default"
                          ref="customerName"
                          v-model="customerName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                          border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': customerNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeCustomerNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="customerNameImage"
                        v-b-modal.customer-name-modal
                        :src="customerNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentCustomerNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeCustomerNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeCustomerNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Contact No. 联络号码"
                      label-for="customerContact"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="customerContact"
                        vid="customerContact"
                        rules=""
                      >
                        <b-form-input
                          id="customerContact"
                          ref="customerContact"
                          v-model="customerContact"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="NRIC"
                      label-for="customerNRIC"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="NRIC"
                        vid="customerNRIC"
                        rules="required"
                      >
                        <b-form-input
                          id="customerNRIC"
                          ref="customerNRIC"
                          v-model="customerNRIC"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="kinDetailsRef"
                title="Next of Kin Details"
                class="custom-collapse-icon"
                :is-visible="kinCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Next of Kin Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label">Next of Kin Name 至亲姓名</span>
                    </div>
                    <b-form-group
                      v-if="currentKinNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Next of Kin Name"
                        rules=""
                      >
                        <b-form-input
                          id="input-default"
                          ref="kinName"
                          v-model="kinName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                          border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': kinNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeKinNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="kinNameImage"
                        v-b-modal.kin-name-modal
                        :src="kinNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentKinNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeKinNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeKinNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Contact No. 联络号码"
                      label-for="kinContact"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="kinContact"
                        vid="kinContact"
                        rules=""
                      >
                        <b-form-input
                          id="kinContact"
                          ref="kinContact"
                          v-model="kinContact"
                          placeholder="Enter"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="NRIC"
                      label-for="kinNRIC"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="NRIC"
                        vid="kinNRIC"
                        rules=""
                      >
                        <b-form-input
                          id="kinNRIC"
                          ref="kinNRIC"
                          v-model="kinNRIC"
                          placeholder="Enter"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="otherDetailsRef"
                title="Other Details"
                class="custom-collapse-icon"
                :is-visible="otherCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Other Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="File No. 文件号码"
                      label-for="fileNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="File No."
                        vid="fileNo"
                        rules="required"
                      >
                        <b-form-input
                          id="fileNo"
                          ref="fileNo"
                          v-model="fileNo"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Niche No. 福位编号"
                      label-for="nicheNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Niche No."
                        vid="nicheNo"
                        rules=""
                      >
                        <b-form-input
                          id="nicheNo"
                          ref="nicheNo"
                          v-model="nicheNo"
                          placeholder="Enter"
                          readonly
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Agent Name"
                      label-for="agentName"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Agent Name"
                        vid="agentName"
                        rules=""
                      >
                        <b-form-input
                          id="agentName"
                          ref="agentName"
                          v-model="agentName"
                          placeholder="Enter"
                          readonly
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Agent Contact No. 代理联络电话"
                      label-for="agentContact"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="agentContact"
                        vid="agentContact"
                        rules=""
                      >
                        <b-form-input
                          id="agentContact"
                          ref="agentContact"
                          v-model="agentContact"
                          placeholder="Enter"
                          readonly
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Remarks"
                      label-for="remarks"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="remarks"
                        vid="remarks"
                        rules=""
                      >
                        <b-form-input
                          id="remarks"
                          ref="remarks"
                          v-model="remarks"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="photoDetailsRef"
                title="Photo Received/Returned"
                class="custom-collapse-icon"
                :is-visible="photoCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Photo Received/Returned</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <div class="p-1 mt-50">
                    <span class="text-bold-black">Photo Received 照片提供</span>
                  </div>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Received By 收件"
                      label-for="photoReceivedBy"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="photoReceivedBy"
                        vid="photoReceivedBy"
                        rules=""
                      >
                        <b-form-input
                          id="photoReceivedBy"
                          ref="photoReceivedBy"
                          v-model="photoReceivedBy"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Date 日期"
                      vid="photoReceivedDate"
                      rules=""
                    >
                      <b-form-group
                        label="Date 日期"
                        label-class="field-label"
                        label-for="photoReceivedDate"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <b-input-group>
                          <flat-pickr
                            id="photoReceivedDate"
                            ref="photoReceivedDate"
                            v-model="photoReceivedDate"
                            class="form-control flat-pickr-group"
                            placeholder="Date 日期"
                            :config="flatPickrConfig"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <div class="p-1 mt-50">
                    <span class="text-bold-black">Photo Returned 照片归还</span>
                  </div>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Claimed By 照片领回"
                      label-for="photoClaimedBy"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="photoClaimedBy"
                        vid="photoClaimedBy"
                        rules=""
                      >
                        <b-form-input
                          id="photoClaimedBy"
                          ref="photoClaimedBy"
                          v-model="photoClaimedBy"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Date 日期"
                      vid="photoClaimedDate"
                      rules=""
                    >
                      <b-form-group
                        label="Date 日期"
                        label-class="field-label"
                        label-for="photoClaimedDate"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <b-input-group>
                          <flat-pickr
                            id="photoClaimedDate"
                            ref="photoClaimedDate"
                            v-model="photoClaimedDate"
                            class="form-control flat-pickr-group"
                            placeholder="Date 日期"
                            :config="flatPickrConfig"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
            <div v-if="!editFormInOtherStatus">
              <b-card
                v-if="requestForm.status == 'Ready to sign'"
                class="mt-2 service-forms-card"
              >
                <template #header>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      class=""
                      size="20"
                      style="color: #fff;"
                    />
                  </b-button>
                  <h3 class="align-middle mr-auto">
                    <span class="step-class">signing required</span>
                    Ready to Sign?
                  </h3>
                </template>
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>If you're prepared to proceed, please press the button to indicate your agreement to this form.</span>
                </b-card-text>

                <!-- Button: Send Invoice -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mb-75"
                  block
                  @click="startSignProcess"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">Sign now</span>
                </b-button>
              </b-card>
              <b-card
                v-if="requestForm.status == 'Signing'"
                class="mt-2 service-forms-card"
              >
                <template #header>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      class=""
                      size="20"
                      style="color: #fff;"
                    />
                  </b-button>
                  <h3 class="align-middle mr-auto">
                    <span class="step-class">signing required</span>
                    Start signing
                  </h3>
                </template>
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>This form requires 3 signatures from:</span>
                </b-card-text>

                <app-timeline class="sign-list">
                  <app-timeline-item
                    v-if="isPurchaserSign"
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                  <app-timeline-item
                    v-if="inChargeSign"
                    title="Person in Charge"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="Person in Charge"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                  <app-timeline-item
                    v-if="verifiedSign"
                    title="System Updated By"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="System Updated By"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                </app-timeline>

                <hr class="dividerHR signing-stage__divider">

                <b-card-text
                  class="mt-2"
                >
                  <span>After submitted the signed form, the system will generate a PDF copy and email to customer.</span>
                </b-card-text>
              </b-card>

              <b-card
                v-if="requestForm.status == 'Signed and Sent'"
                class="mt-2 service-forms-card"
              >
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>This form requires 3 signatures from:</span>
                </b-card-text>

                <app-timeline class="sign-list">
                  <app-timeline-item
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    title="Person in Charge"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-if="verifiedSign"
                    title="System Updated By"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="System Updated By"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                </app-timeline>

                <hr class="dividerHR signing-stage__divider">

                <b-card-text
                  class="mt-2"
                >
                  <b-button
                    variant="outline-primary"
                    class="w-100"
                    @click="resendSignedFormModal"
                  >
                    <feather-icon
                      icon="SendIcon"
                      class="mr-50"
                      size="16"
                    />
                    <span>Resend signed form</span>
                  </b-button>
                </b-card-text>
              </b-card>
            </div>
            <div class="d-form__action-btn">
              <b-button
                variant="outline-primary"
                class="mt-2"
                style="background: #FFF;"
                :to="{ name: 'operation-service-requests-show', params: { id: serviceRequest._id } }"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  class="mr-50"
                  size="16"
                />
                <span>Return to {{ serviceRequest.stringID }}</span>
              </b-button>
              <b-button
                v-if="!editFormInOtherStatus && requestForm.status != 'To prepare'"
                variant="outline-primary"
                class="mt-2 ml-auto"
                style="background: #FFF;"
                @click="editForm"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                />
              </b-button>
            </div>
          </b-col>
          <b-col
            class="content-header-right my-1 d-md-block"
            style="position: relative;"
            md="9"
            cols="12"
          >
            <!-- Table Container Card -->
            <b-card
              no-body
              class="mb-0"
            >
              <div class="urn-form-wrapper d-form_wrapper__adjust-margin">
                <div class="orn-form-header d-flex justify-content-between mb-75">
                  <div class="d-form-logo_section" />
                  <div class="d-form-no_section text-right">
                    <h5>
                      {{ digitalForm.formNumber }}
                    </h5>
                    <h5>
                      {{ digitalForm.revision }}
                    </h5>
                  </div>
                </div>
                <div class="urn-repository-order_section">
                  <div class="b-form-section-title my-2">
                    <h3>URN COMPARTMENT PLAQUE ENGRAVING NOTICE <span>福位刻碑表格</span></h3>
                  </div>
                  <b-row>
                    <b-col md="8">
                      <div class="d-form-border my-2">
                        <div class="d-form-urn-compartment-box-left-section">
                          <b-form-checkbox-group
                            v-model="genderVal"
                            :options="genderOptions"
                            name="gender"
                            class="form-custom-input-checkbox"
                            style="pointer-events: none;"
                          />
                        </div>
                        <div class="d-form-urn-compartment-box-left-main-section">
                          <b-row>
                            <b-col md="12">
                              <b-form-group
                                label-cols="12"
                                label-cols-lg="2"
                                label-for="input-default"
                                label-class="label-width-110"
                                class="align-items-center"
                              >
                                <template #label>
                                  <span>Deceased:</span>
                                  <br>
                                  <span>往生者</span>
                                </template>
                                <div
                                  v-if="currentDeceasedNameType == 'input'"
                                  class="empty-input__text"
                                  :class="{ 'bg-white': (requestForm.status != 'To prepare' || deceasedName), 'edit-mode': editFormInOtherStatus }"
                                  @click="handleClick('deceasedName', 'deceasedDetailsRef')"
                                >
                                  <p
                                    v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !deceasedName"
                                    class="placeholder__text"
                                  />
                                  <p v-else>
                                    {{ deceasedName || '-' }}
                                  </p>
                                </div>
                                <div
                                  v-else
                                  :class="{ 'signed': deceasedNameImage }"
                                  style="border-bottom: 1px solid #000"
                                >
                                  <b-img
                                    v-if="deceasedNameImage"
                                    :src="deceasedNameImage"
                                    class="w-100 signature-image"
                                    alt="sign"
                                  />
                                </div>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="12">
                              <b-form-group
                                label-cols="12"
                                label-cols-lg="2"
                                label-for="input-default"
                                label-class="label-width-110"
                                class="align-items-center"
                              >
                                <template #label>
                                  <span>Native Place:</span>
                                  <br>
                                  <span>籍贯</span>
                                </template>
                                <div
                                  v-if="currentDeceasedNativeType == 'input'"
                                  class="empty-input__text"
                                  :class="{ 'bg-white': (requestForm.status != 'To prepare' || deceasedNative), 'edit-mode': editFormInOtherStatus }"
                                  @click="handleClick('deceasedNative', 'deceasedDetailsRef')"
                                >
                                  <p
                                    v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !deceasedNative"
                                    class="placeholder__text"
                                  />
                                  <p v-else>
                                    {{ deceasedNative || '-' }}
                                  </p>
                                </div>
                                <div
                                  v-else
                                  :class="{ 'signed': deceasedNativeImage }"
                                  style="border-bottom: 1px solid #000"
                                >
                                  <b-img
                                    v-if="deceasedNativeImage"
                                    :src="deceasedNativeImage"
                                    class="w-100 signature-image"
                                    alt="sign"
                                  />
                                </div>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <div class="input-divider-heading-block">
                            <p>
                              Birth date/time 生日/时
                            </p>
                          </div>
                          <b-row>
                            <b-col md="12">
                              <b-form-group
                                label-cols="12"
                                label-cols-lg="2"
                                label-for="input-default"
                                label-class="label-width-110"
                                class="align-items-center"
                              >
                                <template #label>
                                  <span>Gregorian:</span>
                                  <br>
                                  <span>阳历</span>
                                </template>
                                <div
                                  class="empty-input__text"
                                  :class="{ 'bg-white': (requestForm.status != 'To prepare' || birthDate), 'edit-mode': editFormInOtherStatus }"
                                  @click="handleClickCleaveField('birthDate', 'deceasedDetailsRef')"
                                >
                                  <p
                                    v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !birthDate"
                                    class="placeholder__text"
                                  />
                                  <p v-else>
                                    {{ birthDate || '-' }}
                                  </p>
                                </div>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="12">
                              <b-form-group
                                label-cols="12"
                                label-cols-lg="2"
                                label-for="input-default"
                                label-class="label-width-110"
                                class="align-items-center"
                              >
                                <template #label>
                                  <span>Lunar:</span>
                                  <br>
                                  <span>农历</span>
                                </template>
                                <div
                                  class="d-form-input-flex"
                                >
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append"
                                      :class="{ 'bg-white': (requestForm.status != 'To prepare' || birthLunarYear), 'edit-mode': editFormInOtherStatus }"
                                      @click="handleClick('birthLunarYear', 'deceasedDetailsRef')"
                                    >
                                      <p
                                        v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !birthLunarYear"
                                        class="placeholder__text"
                                      />
                                      <p v-else>
                                        {{ birthLunarYear || '-' }}
                                      </p>
                                      <p>
                                        年
                                      </p>
                                    </div>
                                  </b-input-group>
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append"
                                      :class="{ 'bg-white': (requestForm.status != 'To prepare' || birthLunarMonth), 'edit-mode': editFormInOtherStatus }"
                                      @click="handleClick('birthLunarMonth', 'deceasedDetailsRef')"
                                    >
                                      <p
                                        v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !birthLunarMonth"
                                        class="placeholder__text"
                                      />
                                      <p v-else>
                                        {{ birthLunarMonth || '-' }}
                                      </p>
                                      <p>
                                        月
                                      </p>
                                    </div>
                                  </b-input-group>
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append"
                                      :class="{ 'bg-white': (requestForm.status != 'To prepare' || birthLunarDay), 'edit-mode': editFormInOtherStatus }"
                                      @click="handleClick('birthLunarDay', 'deceasedDetailsRef')"
                                    >
                                      <p
                                        v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !birthLunarDay"
                                        class="placeholder__text"
                                      />
                                      <p v-else>
                                        {{ birthLunarDay || '-' }}
                                      </p>
                                      <p>
                                        日
                                      </p>
                                    </div>
                                  </b-input-group>
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append"
                                      :class="{ 'bg-white': (requestForm.status != 'To prepare' || birthLunarTime), 'edit-mode': editFormInOtherStatus }"
                                      @click="handleClick('birthLunarTime', 'deceasedDetailsRef')"
                                    >
                                      <p
                                        v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !birthLunarTime"
                                        class="placeholder__text"
                                      />
                                      <p v-else>
                                        {{ birthLunarTime || '-' }}
                                      </p>
                                      <p>
                                        时
                                      </p>
                                    </div>
                                  </b-input-group>
                                </div>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <div class="input-divider-heading-block">
                            <p>
                              Death date/time 歿日/时
                            </p>
                          </div>
                          <b-row>
                            <b-col md="12">
                              <b-form-group
                                label-cols="12"
                                label-cols-lg="2"
                                label-class="label-width-110"
                                label-for="input-default"
                              >
                                <template #label>
                                  <span>Gregorian:</span>
                                  <br>
                                  <span>阳历</span>
                                </template>
                                <div
                                  class="empty-input__text"
                                  :class="{ 'bg-white': (requestForm.status != 'To prepare' || deathDate), 'edit-mode': editFormInOtherStatus }"
                                  @click="handleClickDateField('deathDate', 'deceasedDetailsRef')"
                                >
                                  <p
                                    v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !deathDate"
                                    class="placeholder__text"
                                  />
                                  <p v-else>
                                    {{ deathDate || '-' }}
                                  </p>
                                </div>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="12">
                              <b-form-group
                                label-cols="12"
                                label-cols-lg="2"
                                label-for="input-default"
                                append=".00"
                                label-class="label-width-110"
                                class="align-items-center"
                              >
                                <template #label>
                                  <span>Lunar:</span>
                                  <br>
                                  <span>农历</span>
                                </template>
                                <div
                                  class="d-form-input-flex"
                                >
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append"
                                      :class="{ 'bg-white': (requestForm.status != 'To prepare' || deathLunarYear), 'edit-mode': editFormInOtherStatus }"
                                      @click="handleClick('deathLunarYear', 'deceasedDetailsRef')"
                                    >
                                      <p
                                        v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !deathLunarYear"
                                        class="placeholder__text"
                                      />
                                      <p v-else>
                                        {{ deathLunarYear || '-' }}
                                      </p>
                                      <p>
                                        年
                                      </p>
                                    </div>
                                  </b-input-group>
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append"
                                      :class="{ 'bg-white': (requestForm.status != 'To prepare' || deathLunarMonth), 'edit-mode': editFormInOtherStatus }"
                                      @click="handleClick('deathLunarMonth', 'deceasedDetailsRef')"
                                    >
                                      <p
                                        v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !deathLunarMonth"
                                        class="placeholder__text"
                                      />
                                      <p v-else>
                                        {{ deathLunarMonth || '-' }}
                                      </p>
                                      <p>
                                        月
                                      </p>
                                    </div>
                                  </b-input-group>
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append"
                                      :class="{ 'bg-white': (requestForm.status != 'To prepare' || deathLunarDay), 'edit-mode': editFormInOtherStatus }"
                                      @click="handleClick('deathLunarDay', 'deceasedDetailsRef')"
                                    >
                                      <p
                                        v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !deathLunarDay"
                                        class="placeholder__text"
                                      />
                                      <p v-else>
                                        {{ deathLunarDay || '-' }}
                                      </p>
                                      <p>
                                        日
                                      </p>
                                    </div>
                                  </b-input-group>
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append"
                                      :class="{ 'bg-white': (requestForm.status != 'To prepare' || deathLunarTime), 'edit-mode': editFormInOtherStatus }"
                                      @click="handleClick('deathLunarTime', 'deceasedDetailsRef')"
                                    >
                                      <p
                                        v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !deathLunarTime"
                                        class="placeholder__text"
                                      />
                                      <p v-else>
                                        {{ deathLunarTime || '-' }}
                                      </p>
                                      <p>
                                        时
                                      </p>
                                    </div>
                                  </b-input-group>
                                </div>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <div class="d-form-border my-2">
                        <div class="d-form-photo-status-block">
                          <div class="d-form-heading-block">
                            <h4> Photo Received 照片提供</h4>
                          </div>
                          <b-form-group
                            label="Received By 收件:"
                            label-for="input-default"
                          >
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || photoReceivedBy), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('photoReceivedBy', 'photoDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !photoReceivedBy"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ photoReceivedBy || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                          <b-form-group
                            label="Date 日期:"
                            label-for="input-default"
                          >
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || photoReceivedDate), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClickDateField('photoReceivedDate', 'photoDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !photoReceivedDate"
                                class="date__placeholder__text"
                              />
                              <p v-else>
                                {{ photoReceivedDate || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                          <div class="d-form-heading-block mt-1">
                            <h4>Photo Returned 照片归还</h4>
                          </div>
                          <b-form-group
                            label="Claimed By 照片领回:"
                            label-for="input-default"
                          >
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || photoClaimedBy), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('photoClaimedBy', 'photoDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !photoClaimedBy"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ photoClaimedBy || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                          <b-form-group
                            label="Date 日期:"
                            label-for="input-default"
                          >
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || photoClaimedDate), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClickDateField('photoClaimedDate', 'photoDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !photoClaimedDate"
                                class="date__placeholder__text"
                              />
                              <p v-else>
                                {{ photoClaimedDate || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                          <div class="d-form-heading-block mt-1">
                            <h4>Remarks 备注</h4>
                          </div>
                          <div>
                            <p>All Chinese Character on the plaque must be in traditional Chinese text.<br><span>碑文均採繁體</span></p>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="1"
                        label-for="input-default"
                        label-class="label-width-150"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Purchaser*</span>
                          <br>
                          <span>购买者</span>
                        </template>
                        <div
                          v-if="currentCustomerNameType == 'input'"
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerName), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('customerName', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerName"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ customerName || '-' }}
                          </p>
                        </div>
                        <div
                          v-else
                          :class="{ 'signed': customerNameImage }"
                          style="border-bottom: 1px solid #000"
                        >
                          <b-img
                            v-if="customerNameImage"
                            :src="customerNameImage"
                            class="w-100 signature-image"
                            alt="sign"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="2"
                        label-for="input-default"
                        label-class="label-width-150"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Contact No.</span>
                          <br>
                          <span>联络号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerContact), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('customerContact', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerContact"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ customerContact || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="2"
                        label-for="input-default"
                        label-class="label-width-150"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>NRIC*</span>
                          <br>
                          <span>身分证号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerNRIC), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('customerNRIC', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerNRIC"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ customerNRIC || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="1"
                        label-for="input-default"
                        label-class="label-width-150"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Next of Kin Name*</span>
                          <br>
                          <span>至亲姓名</span>
                        </template>
                        <div
                          v-if="currentKinNameType == 'input'"
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinName), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('kinName', 'kinDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinName"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ kinName || '-' }}
                          </p>
                        </div>
                        <div
                          v-else
                          :class="{ 'signed': kinNameImage }"
                          style="border-bottom: 1px solid #000"
                        >
                          <b-img
                            v-if="kinNameImage"
                            :src="kinNameImage"
                            class="w-100 signature-image"
                            alt="sign"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="2"
                        label-for="input-default"
                        label-class="label-width-150"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Contact No.</span>
                          <br>
                          <span>联络号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinContact), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('kinContact', 'kinDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinContact"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ kinContact || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="2"
                        label-for="input-default"
                        label-class="label-width-150"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>NRIC*</span>
                          <br>
                          <span>身分证号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinNRIC), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('kinNRIC', 'kinDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinNRIC"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ kinNRIC || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="2"
                        label-for="input-default"
                        label-class="label-width-150"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>File No.*</span>
                          <br>
                          <span>文件号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || fileNo), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('fileNo', 'otherDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !fileNo"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ fileNo || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="2"
                        label-for="input-default"
                        label-class="label-width-150"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Niche No.*</span>
                          <br>
                          <span>福位编号</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || nicheNo), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('nicheNo', 'otherDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !nicheNo"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ nicheNo || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>   <b-row>
                    <b-col md="6">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="2"
                        label-for="input-default"
                        label-class="label-width-150"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Agent Name*</span>
                          <br>
                          <span>代理姓名</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || agentName), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('agentName', 'otherDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !agentName"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ agentName || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="2"
                        label-for="input-default"
                        label-class="label-width-150"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Agent Contact No.</span>
                          <br>
                          <span>代理联络电话</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || agentContact), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('agentContact', 'otherDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !agentContact"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ agentContact || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="1"
                        label-for="input-default"
                        label-class="label-width-150"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Remarks</span>
                          <br>
                          <span>备注</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || remarks), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('remarks', 'otherDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !remarks"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ remarks || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="d-form-warning-text-block">
                    <p class="d-form-warning-text">
                      Disclaimer: I hereby confirm that all the information and characters herein are correct and authorised Mount Prajna Ltd to engrave the Plaque in accordance to this notice. The Plaque will be available after 30 days from the date of this notice.
                    </p>
                    <p class="d-form-warning-text">
                      声明： 本人确定所附资料正确无误，并请遵照刻碑，本表格签署三十天后，即请安排上碑。
                    </p>
                  </div>
                  <b-row class="mt-5">
                    <b-col md="6">
                      <p class="d-form-signature-title">Purchaser’s / Next of Kin’s Signatory & Date
                        <br>
                        <span>购买者或至亲签名</span>
                      </p>
                      <div
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-modal.purchaser-sign-modal
                        class="form-signature-box"
                        :class="{ 'signed': isPurchaserSign, 'edit-mode': editFormInOtherStatus }"
                        :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                      >
                        <div class="form-signature-content">
                          <span
                            v-if="!isPurchaserSign"
                            class="form-signature-text"
                          >
                            Sign here
                          </span>
                          <b-img
                            v-else
                            :src="purchaserSign"
                            alt="authorized-sign"
                          />
                        </div>
                      </div>
                      <div class="signature-date-block">
                        <p>Name 姓名: {{ customerName }}</p>
                        <p v-if="purchaserSignDate">
                          Date 日期: {{ dateFormatWithTime(purchaserSignDate) }}
                        </p>
                        <p v-else>
                          Date 日期:
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                  <div class="d-form-border mt-4">
                    <h5 class="d-form-office-use-title">
                      For office use 公务用栏
                    </h5>
                    <b-row>
                      <b-col
                        md="6"
                        class="d-form-border-right pr-0 border-right-none padding-right-auto"
                      >
                        <div class="padding-20">
                          <p class="d-form-signature-title">
                            Person in Charge 服务人员:
                          </p>
                          <div
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            v-b-modal.in-charge-sign-modal
                            class="form-signature-box"
                            :class="{ 'signed': inChargeSign, 'edit-mode': editFormInOtherStatus }"
                            :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                          >
                            <div class="form-signature-content">
                              <span
                                v-if="!inChargeSign"
                                class="form-signature-text"
                              >
                                Sign here
                              </span>
                              <b-img
                                v-else
                                :src="inChargeSignSrc"
                                alt="authorized-sign"
                              />
                            </div>
                          </div>
                          <div class="signature-date-block">
                            <p>Name 姓名: {{ inChargeSignName }}</p>
                            <p v-if="inChargeSignDate">
                              Date 日期: {{ dateFormatWithTime(inChargeSignDate) }}
                            </p>
                            <p v-else>
                              Date 日期:
                            </p>
                          </div>
                        </div>
                      </b-col>
                      <b-col
                        md="6"
                        class="pl-0 padding-left-auto"
                      >
                        <div class="padding-20">
                          <p class="d-form-signature-title">
                            System Updated By 系统输入人员
                          </p>
                          <div
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            v-b-modal.verified-sign-modal
                            class="form-signature-box"
                            :class="{ 'signed': verifiedSign, 'edit-mode': editFormInOtherStatus }"
                            :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                          >
                            <div class="form-signature-content">
                              <span
                                v-if="!verifiedSign"
                                class="form-signature-text"
                              >
                                Sign here
                              </span>
                              <b-img
                                v-else
                                :src="verifiedSignSrc"
                                alt="authorized-sign"
                              />
                            </div>
                          </div>
                          <div class="signature-date-block">
                            <p>Name 姓名: {{ verifiedSignName }}</p>
                            <p v-if="verifiedSignDate">
                              Date 日期: {{ dateFormatWithTime(verifiedSignDate) }}
                            </p>
                            <p v-else>
                              Date 日期:
                            </p>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <div
          v-if="requestForm.status == 'To prepare'"
          class="d-form_action-btn_group wrap-border save-nav"
        >
          <p
            v-if="isMobile()"
            class="margin-left-20 mt-50 mb-50 d-form_action-title"
          >
            <span>Urn Compartment Plaque Engraving Notice</span>
          </p>
          <b-nav
            :class="{'d-form_action-btn_tablet': isMobile()}"
            class="padding-left-right-20"
          >
            <li
              v-if="!isMobile()"
              class="nav-item"
            >
              <span>Urn Compartment Plaque Engraving Notice</span>
            </li>
            <li
              class="nav-item"
              :class="{'d-form_action-btn__mob-li': isMobile(), 'ml-auto margin-right-5': !isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="outline-primary"
                @click="submitSaveForm('saveOnly')"
              >
                Save changes
              </b-button>
            </li>
            <li
              class="nav-item d-form_action-second_btn"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                @click="makeFormNotRequired"
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span class="align-middle">Mark as Not Required</span>
              </b-button>
            </li>
            <li
              class="nav-item d-form_action-third_btn"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                @click="submitSaveForm('readyToSign')"
              >
                <feather-icon
                  icon="FeatherIcon"
                  class="mr-50"
                />
                <span class="align-middle">Mark as Ready to Sign</span>
              </b-button>
            </li>
          </b-nav>
        </div>

        <div
          v-if="editFormInOtherStatus"
          class="d-form_action-btn_group wrap-border save-nav"
        >
          <p
            v-if="isMobile()"
            class="margin-left-20 mt-50 mb-50 d-form_action-title"
          >
            <span>Editing Urn Compartment Plaque Engraving Notice</span>
          </p>
          <b-nav
            class="padding-left-right-20"
            :class="{'d-form_action-btn_tablet': isMobile()}"
          >
            <li
              v-if="!isMobile()"
              class="nav-item"
            >
              <span>Editing Urn Compartment Plaque Engraving Notice</span>
            </li>
            <li
              :class="{'ml-auto margin-right-5': !isMobile(), 'mob__cancel-btn': isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="outline-primary"
                class="action-cancel_btn"
                @click="editFormInOtherStatus = false; makeFormValues()"
              >
                Cancel Editing
              </b-button>
            </li>
            <li
              class="nav-item"
              :class="{'d-form_action-editing__btn': isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                class="action-done_btn"
                @click="submitSaveFormConfirmation()"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                <span class="align-middle">Done</span>
              </b-button>
            </li>
          </b-nav>
        </div>
      </b-form>
    </validation-observer>
    <!-- authorized signature modal -->
    <b-modal
      id="purchaser-sign-modal"
      ref="purchaser-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Authorized Signature"
      @ok="closePurchaserSignModal"
      @cancel="closePurchaserSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Purchaser’s / Next of Kin’s Signatory & Date</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearPurchaserSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePurchaserSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ authorized signature modal -->
    <!-- verified by signature modal -->
    <b-modal
      id="verified-sign-modal"
      ref="verified-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Verified By"
      @ok="closeVerifiedSignModal"
      @cancel="closeVerifiedSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">System Updated By</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearVerifiedSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveVerifiedSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ verified by signature modal -->
    <b-modal
      id="in-charge-sign-modal"
      ref="in-charge-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Person In Charge Signature"
      @ok="closeInChargeSignModal"
      @cancel="closeInChargeSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Person in Charge</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearInChargeSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveInChargeSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ verified by signature modal -->
    <!-- customer name modal -->
    <b-modal
      id="customer-name-modal"
      ref="customer-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeCustomerNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="customerSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="customerNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearCustomerName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveCustomerName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- customer name -->
    <!-- kin name modal -->
    <b-modal
      id="kin-name-modal"
      ref="kin-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeKinNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="kinSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="kinNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearKinName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveKinName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- kin name -->

    <!-- deceased name modal -->
    <b-modal
      id="deceased-name-modal"
      ref="deceased-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeDeceasedNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="deceasedNameSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="deceasedNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearDeceasedName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveDeceasedName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- deceased name -->

    <!-- deceased native modal -->
    <b-modal
      id="deceased-native-modal"
      ref="deceased-native-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeDeceasedNativeModal"
    >
      <div class="">
        <VueSignaturePad
          ref="deceasedNativeSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="deceasedNativeOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearDeceasedNative()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveDeceasedNative()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- deceased name -->

    <!-- submit signed form -->
    <b-modal
      id="submit-form-modal"
      ref="submit-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this signed form?"
      @ok="submitSignedForm"
      @cancel="closeSubmitFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="+65 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="resend-form-modal"
      ref="resend-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Resend this form?"
      @ok="resendSignedForm"
      @cancel="closeResendFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="+65 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to Form
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="edit-form-modal"
      ref="edit-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this edited form?"
      @ok="submitSaveForm('edited', true)"
      @cancel="cancelEditing"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="+65 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BImg, BButton, BNav, BForm, BTab, BTabs, BInputGroup, BFormRadioGroup,
  BInputGroupAppend, BFormCheckboxGroup, BFormTextarea,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { required } from '@validations'
// eslint-disable-next-line
    import 'quill/dist/quill.core.css'
// eslint-disable-next-line
    import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
    import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContentCustom from '../../ToastificationContentCustom.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BButton,
    BNav,
    BForm,
    BTab,
    BTabs,
    BInputGroup,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BInputGroupAppend,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentCustom,
    quillEditor,
    flatPickr,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      cleaveOptions: {
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        delimiter: {
          delimiter: '/',
          blocks: [2, 2, 4],
          uppercase: true,
          numericOnly: true,
        },
      },
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      verifiedSign: false,
      verifiedSignSrc: '',
      verifiedSignDate: '',
      verifiedSignName: '',
      inChargeSign: false,
      inChargeSignSrc: '',
      inChargeSignDate: '',
      inChargeSignName: '',
      customerNameImageError: false,
      kinNameImageError: false,
      deceasedNameImageError: false,
      deceasedNativeImageError: false,
      purchaserDetailsCollapse: false,
      photoCollapse: false,
      purchaserCollapse: false,
      kinCollapse: false,
      otherCollapse: false,
      genderCollapse: true,
      deceasedCollapse: true,
      editFormInOtherStatus: false,
      tabActive: 'email',
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', maxDate: 'today', disableMobile: true,
      },
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      deceasedNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.deceasedNameSignaturePad.resizeCanvas() },
      },
      deceasedNativeOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.deceasedNativeSignaturePad.resizeCanvas() },
      },
      currentCustomerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerAddress: '',
      customerContact: '',
      customerNRIC: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinAddress: '',
      kinContact: '',
      kinNRIC: '',
      fileNo: '',
      nicheNo: '',
      agentName: '',
      agentContact: '',
      remarks: '',
      gender: '',
      genderVal: [],
      currentDeceasedNameType: 'input',
      deceasedName: '',
      deceasedNameImage: '',
      currentDeceasedNativeType: 'input',
      deceasedNative: '',
      deceasedNativeImage: '',
      birthDate: `00/00/${new Date().getFullYear()}`,
      birthLunarYear: '',
      birthLunarMonth: '',
      birthLunarDay: '',
      birthLunarTime: '',
      deathDate: `00/00/${new Date().getFullYear()}`,
      deathLunarYear: '',
      deathLunarMonth: '',
      deathLunarDay: '',
      deathLunarTime: '',
      photoClaimedBy: '',
      photoClaimedDate: '',
      photoReceivedBy: '',
      photoReceivedDate: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      existingVerifiedSignSrcObject: {},
      existingPurchaserSignObject: {},
      existingInChargeSignSrcObject: {},
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Type your message here',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Type your message here',
      },
      genderOptions: [
        { text: 'Male 男', value: 'Male 男' },
        { text: 'Female 女', value: 'Female 女' },
      ],
      required,
    }
  },
  created() {
    this.makeFormValues()
    this.customerMobileVal = this.requestForm.customerContact
    this.customerEmailVal = this.requestForm.customerEmail
  },
  methods: {
    makeFormValues() {
      this.fileNo = this.serviceRequest.fileNo
      this.nicheNo = this.serviceRequest.serviceForm.deceasedNiche
      this.agentName = this.serviceRequest.serviceForm.agentName
      this.agentContact = this.serviceRequest.serviceForm.agentContact
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.currentCustomerNameType = 'canvas'
        } else {
          this.currentCustomerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.customerNRIC = this.serviceRequest.formValues.find(o => o.key === 'customerNRIC')?.value
      this.customerContact = this.serviceRequest.formValues.find(o => o.key === 'customerContact')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.currentKinNameType = 'canvas'
        } else {
          this.currentKinNameType = 'input'
          this.kinName = kinNameObject.value
        }
      }
      this.kinNRIC = this.serviceRequest.formValues.find(o => o.key === 'kinNRIC')?.value
      this.kinContact = this.serviceRequest.formValues.find(o => o.key === 'kinContact')?.value
      if (this.requestForm.values.length) {
        const remarksObject = this.requestForm.values.find(o => o.key === 'remarks')
        if (remarksObject) {
          this.remarks = remarksObject.value
        }
        const genderObject = this.requestForm.values.find(o => o.key === 'gender')
        if (genderObject) {
          this.gender = genderObject.value
          this.genderVal = [genderObject.value]
        }
        const deceasedNameObject = this.requestForm.values.find(o => o.key === 'deceasedName')
        if (deceasedNameObject) {
          if (deceasedNameObject.value.startsWith('data:image/')) {
            this.deceasedNameImage = deceasedNameObject.value
            this.currentDeceasedNameType = 'canvas'
          } else {
            this.currentDeceasedNameType = 'input'
            this.deceasedName = deceasedNameObject.value
          }
        }
        const deceasedNativeObject = this.requestForm.values.find(o => o.key === 'deceasedNative')
        if (deceasedNativeObject) {
          if (deceasedNativeObject.value.startsWith('data:image/')) {
            this.deceasedNativeImage = deceasedNativeObject.value
            this.currentDeceasedNativeType = 'canvas'
          } else {
            this.currentDeceasedNativeType = 'input'
            this.deceasedNative = deceasedNativeObject.value
          }
        }
        const birthDateObject = this.requestForm.values.find(o => o.key === 'birthDate')
        if (birthDateObject) {
          this.birthDate = birthDateObject.value
        }
        const birthLunarYearObject = this.requestForm.values.find(o => o.key === 'birthLunarYear')
        if (birthLunarYearObject) {
          this.birthLunarYear = birthLunarYearObject.value
        }
        const birthLunarMonthObject = this.requestForm.values.find(o => o.key === 'birthLunarMonth')
        if (birthLunarMonthObject) {
          this.birthLunarMonth = birthLunarMonthObject.value
        }
        const birthLunarDayObject = this.requestForm.values.find(o => o.key === 'birthLunarDay')
        if (birthLunarDayObject) {
          this.birthLunarDay = birthLunarDayObject.value
        }
        const birthLunarTimeObject = this.requestForm.values.find(o => o.key === 'birthLunarTime')
        if (birthLunarTimeObject) {
          this.birthLunarTime = birthLunarTimeObject.value
        }
        const deathDateObject = this.requestForm.values.find(o => o.key === 'deathDate')
        if (deathDateObject) {
          this.deathDate = deathDateObject.value
        }
        const deathLunarYearObject = this.requestForm.values.find(o => o.key === 'deathLunarYear')
        if (deathLunarYearObject) {
          this.deathLunarYear = deathLunarYearObject.value
        }
        const deathLunarMonthObject = this.requestForm.values.find(o => o.key === 'deathLunarMonth')
        if (deathLunarMonthObject) {
          this.deathLunarMonth = deathLunarMonthObject.value
        }
        const deathLunarDayObject = this.requestForm.values.find(o => o.key === 'deathLunarDay')
        if (deathLunarDayObject) {
          this.deathLunarDay = deathLunarDayObject.value
        }
        const deathLunarTimeObject = this.requestForm.values.find(o => o.key === 'deathLunarTime')
        if (deathLunarTimeObject) {
          this.deathLunarTime = deathLunarTimeObject.value
        }
        const photoClaimedByObject = this.requestForm.values.find(o => o.key === 'photoClaimedBy')
        if (photoClaimedByObject) {
          this.photoClaimedBy = photoClaimedByObject.value
        }
        const photoClaimedDateObject = this.requestForm.values.find(o => o.key === 'photoClaimedDate')
        if (photoClaimedDateObject) {
          this.photoClaimedDate = photoClaimedDateObject.value
        }
        const photoReceivedByObject = this.requestForm.values.find(o => o.key === 'photoReceivedBy')
        if (photoReceivedByObject) {
          this.photoReceivedBy = photoReceivedByObject.value
        }
        const photoReceivedDateObject = this.requestForm.values.find(o => o.key === 'photoReceivedDate')
        if (photoReceivedDateObject) {
          this.photoReceivedDate = photoReceivedDateObject.value
        }
        const purchaserSignObject = this.requestForm.values.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject) {
          this.existingPurchaserSignObject = purchaserSignObject
          this.purchaserSign = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
        const verifiedSignSrcObject = this.requestForm.values.find(o => o.key === 'verifiedSignSrc')
        if (verifiedSignSrcObject) {
          this.existingVerifiedSignSrcObject = verifiedSignSrcObject
          this.verifiedSignSrc = verifiedSignSrcObject.value
          this.verifiedSign = true
          this.verifiedSignDate = verifiedSignSrcObject.date
          this.verifiedSignName = verifiedSignSrcObject.name
        }
        const inChargeSignSrcObject = this.requestForm.values.find(o => o.key === 'inChargeSignSrc')
        if (inChargeSignSrcObject) {
          this.existingInChargeSignSrcObject = inChargeSignSrcObject
          this.inChargeSignSrc = inChargeSignSrcObject.value
          this.inChargeSign = true
          this.inChargeSignDate = inChargeSignSrcObject.date
          this.inChargeSignName = inChargeSignSrcObject.name
        }
      }
    },
    startSignProcess() {
      const finalStatus = 'Signing'
      const formData = new FormData()
      formData.append('status', finalStatus)
      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editForm() {
      if (this.requestForm.status === 'Not required') {
        const finalStatus = 'To prepare'
        const formData = new FormData()
        formData.append('status', finalStatus)
        this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              await this.$emit('update:requestForm', response.data.data)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.editFormInOtherStatus = true
      }
    },
    makeFormNotRequired() {
      this.$swal({
        title: 'Are you sure you want to ‘Mark as Not Required’ for this form?',
        html: 'The form status will change to ‘Not Required’, all information you have keyed in will be cleared.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: "Yes, I'm sure",
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('status', 'Not required')
            this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(async response => {
                if (response.data.data) {
                  await this.$emit('update:requestForm', response.data.data)
                  this.makeFormValues()
                  this.$swal({
                    title: 'Form Has Been Marked as Not Required',
                    // html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message || '',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    closePurchaserSignModal() {
      this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
    },
    clearPurchaserSign() {
      this.$refs.signaturePad.clearSignature()
    },
    savePurchaserSign() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
        const purchaserSignObj = {
          key: 'purchaserSign',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          this.purchaserSign = data
          this.isPurchaserSign = true
          purchaserSignObj.date = new Date()
          this.existingPurchaserSignObject = purchaserSignObj
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.inChargeSign) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(purchaserSignObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    closeVerifiedSignModal() {
      this.$root.$emit('bv::hide::modal', 'verified-sign-modal', '')
    },
    clearVerifiedSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveVerifiedSign() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'verified-sign-modal', '')
        const verifiedSignObj = {
          key: 'verifiedSignSrc',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          this.verifiedSignSrc = data
          this.verifiedSign = true
          verifiedSignObj.date = new Date()
          verifiedSignObj.name = this.user.name
          verifiedSignObj.admin = this.user._id
          this.existingVerifiedSignSrcObject = verifiedSignObj
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.isPurchaserSign && this.inChargeSign) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(verifiedSignObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    closeInChargeSignModal() {
      this.$root.$emit('bv::hide::modal', 'in-charge-sign-modal', '')
    },
    clearInChargeSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveInChargeSign() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'in-charge-sign-modal', '')
        const inChargeSignObj = {
          key: 'inChargeSignSrc',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          this.inChargeSignSrc = data
          this.inChargeSign = true
          inChargeSignObj.date = new Date()
          inChargeSignObj.name = this.user.name
          inChargeSignObj.admin = this.user._id
          this.existingInChargeSignSrcObject = inChargeSignObj
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.isPurchaserSign) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(inChargeSignObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    closeCustomerNameModal() {
      this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
      if (!this.customerNameImage) {
        this.currentCustomerNameType = 'input'
      }
    },
    closeKinNameModal() {
      this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
      if (!this.kinNameImage) {
        this.currentKinNameType = 'input'
      }
    },
    closeDeceasedNameModal() {
      this.$root.$emit('bv::hide::modal', 'deceased-name-modal', '')
      if (!this.deceasedNameImage) {
        this.currentDeceasedNameType = 'input'
      }
    },
    closeDeceasedNativeModal() {
      this.$root.$emit('bv::hide::modal', 'deceased-native-modal', '')
      if (!this.deceasedNativeImage) {
        this.currentDeceasedNativeType = 'input'
      }
    },
    submitSignedForm(option = null) {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (option === 'edited') {
        formData.append('edited', 'yes')
      }
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.requestForm.formID.name)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)

      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/generate-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
            this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')

            this.makeFormValues()
            this.$toast({
              component: ToastificationContentCustom,
              props: {
                title: 'PDF is ready!',
                text: response.data.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resendSignedFormModal() {
      if (this.requestForm.pdfs.length) {
        this.$root.$emit('bv::show::modal', 'resend-form-modal', '')
      } else {
        this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
      }
    },
    resendSignedForm() {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.requestForm.formID.name)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)

      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/resend-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.$toast({
            component: ToastificationContentCustom,
            props: {
              title: 'PDF is ready!',
              text: response.data.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeSubmitFormModal() {
      this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')
    },
    closeResendFormModal() {
      this.$root.$emit('bv::hide::modal', 'resend-form-modal', '')
    },
    clearCustomerName() {
      this.customerNameImage = ''
      this.$refs.customerSignaturePad.clearSignature()
    },
    clearKinName() {
      this.kinNameImage = ''
      this.$refs.kinSignaturePad.clearSignature()
    },
    clearDeceasedName() {
      this.deceasedNameImage = ''
      this.$refs.deceasedNameSignaturePad.clearSignature()
    },
    clearDeceasedNative() {
      this.deceasedNativeImage = ''
      this.$refs.deceasedNativeSignaturePad.clearSignature()
    },
    saveCustomerName() {
      const { data } = this.$refs.customerSignaturePad.saveSignature()
      if (data) {
        this.customerNameImage = data
        this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
        this.currentCustomerNameType = 'canvas'
      }
    },
    saveDeceasedName() {
      const { data } = this.$refs.deceasedNameSignaturePad.saveSignature()
      if (data) {
        this.deceasedNameImage = data
        this.$root.$emit('bv::hide::modal', 'deceased-name-modal', '')
        this.currentDeceasedNameType = 'canvas'
      }
    },
    saveDeceasedNative() {
      const { data } = this.$refs.deceasedNativeSignaturePad.saveSignature()
      if (data) {
        this.deceasedNativeImage = data
        this.$root.$emit('bv::hide::modal', 'deceased-native-modal', '')
        this.currentDeceasedNativeType = 'canvas'
      }
    },
    saveKinName() {
      const { data } = this.$refs.kinSignaturePad.saveSignature()
      if (data) {
        this.kinNameImage = data
        this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
        this.currentKinNameType = 'canvas'
      }
    },
    async handleClick(field, collapse) {
      if (this.requestForm.status === 'To prepare' || this.editFormInOtherStatus) {
        await this.$refs[collapse].updateVisible(true)
        this.$refs[field].focus()
      }
    },
    async handleClickDateField(field, collapse) {
      if (this.requestForm.status === 'To prepare' || this.editFormInOtherStatus) {
        await this.$refs[collapse].updateVisible(true)
        this.$refs[field].fp.open()
      }
    },
    async handleClickCleaveField(field, collapse) {
      if (this.requestForm.status === 'To prepare' || this.editFormInOtherStatus) {
        await this.$refs[collapse].updateVisible(true)
        // this.$refs[field].$el.onfocus = true
        // console.log(this.$refs[field])
      }
    },
    changeCustomerNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.customerNameImage = ''
              this.currentCustomerNameType = type
            }
          })
      } else {
        this.currentCustomerNameType = type
        this.$root.$emit('bv::show::modal', 'customer-name-modal', '')
      }
    },
    changeDeceasedNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.deceasedNameImage = ''
              this.currentDeceasedNameType = type
            }
          })
      } else {
        this.currentDeceasedNameType = type
        this.$root.$emit('bv::show::modal', 'deceased-name-modal', '')
      }
    },
    changeDeceasedNativeInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.deceasedNativeImage = ''
              this.currentDeceasedNativeType = type
            }
          })
      } else {
        this.currentDeceasedNativeType = type
        this.$root.$emit('bv::show::modal', 'deceased-native-modal', '')
      }
    },
    changeKinNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.kinNameImage = ''
              this.currentKinNameType = type
            }
          })
      } else {
        this.currentKinNameType = type
        this.$root.$emit('bv::show::modal', 'kin-name-modal', '')
      }
    },
    submitSaveFormConfirmation() {
      if (this.requestForm.status === 'Signed and Sent') {
        this.$root.$emit('bv::show::modal', 'edit-form-modal', '')
      } else {
        this.submitSaveForm()
      }
    },
    cancelEditing() {
      this.$root.$emit('bv::hide::modal', 'edit-form-modal', '')
      // this.editFormInOtherStatus = false
      // this.makeFormValues()
    },
    submitSaveForm(option = null, submit = false) {
      this.$refs.serviceRequestFormSave.validate().then(success => {
        let hasCustomerImage = true
        if (this.currentCustomerNameType === 'canvas' && !this.customerNameImage) {
          hasCustomerImage = false
          this.customerNameImageError = true
        }
        // let hasKinImage = true
        // if (this.currentKinNameType === 'canvas' && !this.kinNameImage) {
        //   hasKinImage = false
        //   this.kinNameImageError = true
        // }
        if ((option && option === 'saveOnly') || (success && hasCustomerImage)) {
          const formData = new FormData()
          if (option && option === 'readyToSign') {
            formData.append('readyToSign', true)
          }
          const valueArray = []
          let srValueArray = this.serviceRequest.formValues
          const sfValueObject = {}
          const srValueObject = {}
          const customerNameObj = {
            key: 'customerName',
          }
          if (this.currentCustomerNameType === 'input') {
            customerNameObj.value = this.customerName
          } else {
            customerNameObj.value = this.customerNameImage
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerNameObj.key).concat(customerNameObj)

          const customerNRICObj = {
            key: 'customerNRIC',
            value: this.customerNRIC,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerNRICObj.key).concat(customerNRICObj)

          const customerContactObj = {
            key: 'customerContact',
            value: this.customerContact,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerContactObj.key).concat(customerContactObj)

          const kinNameObj = {
            key: 'kinName',
          }
          if (this.currentKinNameType === 'input') {
            kinNameObj.value = this.kinName
          } else {
            kinNameObj.value = this.kinNameImage
          }
          srValueArray = srValueArray.filter(obj => obj.key !== kinNameObj.key).concat(kinNameObj)

          const kinNRICObj = {
            key: 'kinNRIC',
            value: this.kinNRIC,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== kinNRICObj.key).concat(kinNRICObj)

          const kinContactObj = {
            key: 'kinContact',
            value: this.kinContact,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== kinContactObj.key).concat(kinContactObj)

          srValueObject.fileNo = this.fileNo

          const remarksObj = {
            key: 'remarks',
            value: this.remarks,
          }
          valueArray.push(remarksObj)

          const genderObj = {
            key: 'gender',
            value: this.gender,
          }
          valueArray.push(genderObj)

          const deceasedNameObj = {
            key: 'deceasedName',
          }
          if (this.currentDeceasedNameType === 'input') {
            deceasedNameObj.value = this.deceasedName
          } else {
            deceasedNameObj.value = this.deceasedNameImage
          }
          valueArray.push(deceasedNameObj)

          const deceasedNativeObj = {
            key: 'deceasedNative',
          }
          if (this.currentDeceasedNativeType === 'input') {
            deceasedNativeObj.value = this.deceasedNative
          } else {
            deceasedNativeObj.value = this.deceasedNativeImage
          }
          valueArray.push(deceasedNativeObj)

          const birthDateObj = {
            key: 'birthDate',
            value: this.birthDate,
          }
          valueArray.push(birthDateObj)

          const birthLunarYearObj = {
            key: 'birthLunarYear',
            value: this.birthLunarYear,
          }
          valueArray.push(birthLunarYearObj)

          const birthLunarMonthObj = {
            key: 'birthLunarMonth',
            value: this.birthLunarMonth,
          }
          valueArray.push(birthLunarMonthObj)

          const birthLunarDayObj = {
            key: 'birthLunarDay',
            value: this.birthLunarDay,
          }
          valueArray.push(birthLunarDayObj)

          const birthLunarTimeObj = {
            key: 'birthLunarTime',
            value: this.birthLunarTime,
          }
          valueArray.push(birthLunarTimeObj)

          const deathDateObj = {
            key: 'deathDate',
            value: this.deathDate,
          }
          valueArray.push(deathDateObj)

          const deathLunarYearObj = {
            key: 'deathLunarYear',
            value: this.deathLunarYear,
          }
          valueArray.push(deathLunarYearObj)

          const deathLunarMonthObj = {
            key: 'deathLunarMonth',
            value: this.deathLunarMonth,
          }
          valueArray.push(deathLunarMonthObj)

          const deathLunarDayObj = {
            key: 'deathLunarDay',
            value: this.deathLunarDay,
          }
          valueArray.push(deathLunarDayObj)

          const deathLunarTimeObj = {
            key: 'deathLunarTime',
            value: this.deathLunarTime,
          }
          valueArray.push(deathLunarTimeObj)

          const photoClaimedByObj = {
            key: 'photoClaimedBy',
            value: this.photoClaimedBy,
          }
          valueArray.push(photoClaimedByObj)

          const photoClaimedDateObj = {
            key: 'photoClaimedDate',
            value: this.photoClaimedDate,
          }
          valueArray.push(photoClaimedDateObj)

          const photoReceivedByObj = {
            key: 'photoReceivedBy',
            value: this.photoReceivedBy,
          }
          valueArray.push(photoReceivedByObj)

          const photoReceivedDateObj = {
            key: 'photoReceivedDate',
            value: this.photoReceivedDate,
          }
          valueArray.push(photoReceivedDateObj)

          // let verifiedSignVal = 0
          let inChargeSignVal = 0
          let purchaserSignVal = 0

          if (this.existingVerifiedSignSrcObject.key) {
            // verifiedSignVal = 1
            valueArray.push(this.existingVerifiedSignSrcObject)
          }
          if (this.existingPurchaserSignObject.key) {
            purchaserSignVal = 1
            valueArray.push(this.existingPurchaserSignObject)
          }
          if (this.existingInChargeSignSrcObject.key) {
            inChargeSignVal = 1
            valueArray.push(this.existingInChargeSignSrcObject)
          }

          let allSigned = false
          if (purchaserSignVal && inChargeSignVal) {
            allSigned = true
          }

          // let valueChanged = false
          // if (this.requestForm.status === 'Signed and Sent') {
          //   const difference = valueArray.filter(({ value: id1 }) => !this.requestForm.values.some(({ value: id2 }) => id2 === id1))
          //   if (difference.length) {
          //     const otherValueChanged = difference.find(o => !['installationDate', 'installationTime'].includes(o.key))
          //     if (otherValueChanged) {
          //       valueChanged = true
          //     }
          //   }
          // }

          formData.append('values', JSON.stringify(valueArray))
          formData.append('srValues', JSON.stringify(srValueArray))
          formData.append('sfValues', JSON.stringify(sfValueObject))
          formData.append('srValueObject', JSON.stringify(srValueObject))
          formData.append('allSigned', allSigned)
          formData.append('currentStatus', this.requestForm.status)
          formData.append('formName', this.requestForm.formID.name)
          formData.append('serviceRequest', this.serviceRequest._id)
          formData.append('serviceForm', this.serviceRequest.serviceForm._id)
          formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                await this.$emit('update:serviceRequest', response.data.sr)
                this.makeFormValues()
                this.editFormInOtherStatus = false
                if (option && option === 'edited' && response.data.data.status === 'Signed and Sent' && submit) {
                  this.submitSignedForm('edited')
                } else if (response.data.data.status === 'Signing' && allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Form Data Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          const items = document.getElementsByClassName('is-invalid')
          if (items && items.length) {
            // window.scrollTo({
            //   top: items[0].offsetHeight + 100,
            //   behavior: 'smooth',
            // })
            items[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }
        }
      })
    },
  },
}
</script>
